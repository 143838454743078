import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { CartService } from '../services/cart.service';
import { GlobalsService } from '../services/globals.service';
import { CartTraining } from '../classes/cart-training';
import { Router } from '@angular/router';

@Component({
  selector: 'payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {
  @Input() total: number;
  @ViewChild('paymentForm') addform: NgForm;

  form: FormGroup;
  formSubmitted: boolean = false;

  elements: any;

  stripe: any = (window as any).stripe;

  card: any;

  hasWorkbooksAdded: boolean;
  hasTrainingAdded: boolean;
  hasLicensesAdded: boolean;

  workbooks: Array<Object> = [];
  licenses: Array<Object> = [];
  trainings: Array<CartTraining> = [];

  sendingPayment: boolean = false;

  teachCategories: Array<string>;

  constructor(
    public fb: FormBuilder,
    private dataService: DataService,
    private cartService: CartService,
    public globals: GlobalsService,
    private router: Router
  ) {

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone: [null, [Validators.required, Validators.pattern("[\+0-9 ]{6,20}")]],
      email: ['', [Validators.required]],
      street_address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip_code: ['', [Validators.required, Validators.pattern("[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]")]],
      stripe_token: ['', [Validators.required]],
      what_teach: [''],
      where_teach: [''],
      iam_teach: this.getTeachCategories(),
    })

    this.teachCategories = Object.keys(this.form.controls.iam_teach.value);


    let optionalFields = ['what_teach','where_teach','iam_teach'];

    this.hasWorkbooksAdded = this.cartService.typeInCart("workbook");
    this.hasTrainingAdded = this.cartService.typeInCart("training");
    this.hasLicensesAdded = this.cartService.typeInCart("license");

    if (this.hasTrainingAdded) {
      optionalFields.forEach((field) => {
        this.form.controls[field].setValidators([Validators.required]);
        this.form.controls[field].updateValueAndValidity();
      });

    } else {
      optionalFields.forEach((field) => {
        this.form.controls[field].clearValidators();
        this.form.controls[field].updateValueAndValidity();
      });
    }


  }

  getTeachCategories(): FormGroup {
    return this.fb.group({
      teacher: false,
      tutor: false,
      special_ed_teacher: false,
      speech_and_language_specialist: false,
      ELL_teacher: false,
      parent: false,
      literacy_coach: false,
      other: false
    });
  }

  populateItemsFromCartService() {
    this.workbooks = [];
    this.licenses = [];
    this.trainings = [];

    this.cartService.items.forEach(element => {
      if (element.category == "WORKBOOK" || element.category == "EBOOK") {
        this.workbooks.push({"workbook": element.id, "quantity": element.quantity })
      } else if (element.category == "license") {
        this.licenses.push({"license_id": element.id, "quantity": element.quantity })
      } else if (element.category == "training") {
        let training_element = new CartTraining();
        training_element.training_course = element.id;
        training_element.quantity = element.quantity;
        this.trainings.push(training_element);
      }
    });
  }

  ngOnInit(): void {

    this.sendingPayment = false;

    this.globals.checkoutSuccessful = false;

    this.populateItemsFromCartService();

    if (this.trainings.length > 0) {
      this.hasTrainingAdded = true;
    }

    //STRIPE
    this.elements = this.stripe.elements({
        locale: 'auto'
    });

    /**
       * Card Element
       */
    this.card = this.elements.create("card", {
      style: {
        base: {
          color: "#32325D",
          fontWeight: 500,
          fontSize: "16px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#CFD7DF"
          }
        },
        invalid: {
          color: "#E25950"
        }
      }
    });

    this.card.mount("#example4-card");



    setTimeout(() => {
      this.registerElements([this.card], "example4");
    }, 10);

  }



  createObjToSend(): Object {
    this.populateItemsFromCartService();
    if (this.hasTrainingAdded) {
      this.trainings.forEach((training) => {
        training.what_teach = this.form.controls['what_teach'].value;
        training.where_teach = this.form.controls['where_teach'].value;
        const form_iam_teach = this.form.controls['iam_teach'].value;
        const array_iam_teach = [];
        for (let key in form_iam_teach) {
          if (form_iam_teach[key]) {
            array_iam_teach.push(key)
         }
        }

        training.iam_teach =  array_iam_teach.join(",")
      })
    }

    return {
      'stripe_token': this.form.controls['stripe_token'].value,
      'amount': this.total,
      'billing_details': {
        'address': {
          'city': this.form.controls['city'].value,
          'country': 'US',
          'line1': this.form.controls['street_address'].value,
          'state': this.form.controls['state'].value,
          'zip_code': this.form.controls['zip_code'].value
        },
        "email": this.form.controls['email'].value,
        "name": this.form.controls['last_name'].value+", "+this.form.controls['name'].value,
        "phone": this.form.controls['phone'].value
      },
      'workbooks': this.workbooks,
      'licenses': this.licenses,
      'trainings': this.trainings
    }
  }

  onSubmit() {

    if (this.form.valid) {
      // CONSTRUCT OBJECT TO SEND

      let sendObj = this.createObjToSend();

      this.dataService.sendPayment(sendObj).subscribe((response) => {
        window.scrollTo(0,0);
        this.globals.checkoutSuccessful = true;
        this.cartService.clearCart();

        this.globals.receipt_url = response['receipt_url'];

        let typesInCart = [this.hasLicensesAdded, this.hasTrainingAdded, this.hasWorkbooksAdded]
        let success_type:string;

        if (typesInCart.filter(Boolean).length > 1) {
          success_type = "mixed";
        } else if (this.hasLicensesAdded) {
          success_type = "licenses";
        } else if (this.hasTrainingAdded) {
          success_type = "trainings";
        } else if (this.hasWorkbooksAdded) {
          success_type = "workbooks";
        }

        this.router.navigate(['thank-you', success_type]);

      }, (error) => {
        alert("Sorry, an error has occurred.")
        this.sendingPayment = false;
      })
    } else {
      this.validateAllFormFields(this.form);
    }


  }


  createToken() {
    this.sendingPayment = true;

    this.stripe.createToken(this.card).then((result: { error: { message: string; }; token: any; }) => {
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        this.sendingPayment = false;
      } else {
        // Send the token to your server
        this.stripeTokenHandler(result.token);
      }
    });
  };


  stripeTokenHandler(token: { id: string; }) {
    // Insert the token ID into the form so it gets submitted to the server
    this.form.controls['stripe_token'].setValue(token.id);

    // Submit the form
    this.onSubmit();
  }

  registerElements(elements: any, exampleName: string) {
    var formClass = '.' + exampleName;
    var example = document.querySelector(formClass);

    var form = example.querySelector('form');
    var resetButton = example.querySelector('a.reset');
    var error = document.querySelector('.error');
    var errorMessage = error.querySelector('.message');

    function enableInputs() {
      Array.prototype.forEach.call(
        form.querySelectorAll(
          "input[type='text'], input[type='email'], input[type='tel']"
        ),
        function(input) {
          input.removeAttribute('disabled');
        }
      );
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(this.form.controls).forEach(field => { // {1}
      const control = this.form.get(field);            // {2}
      control.markAsTouched();       // {3}
    });
  }

  getTeachCategoryDisplay(text: string) {
    let textSpaced = text.replace(/_/g, ' ');
    return textSpaced.charAt(0).toUpperCase() + textSpaced.slice(1)
  }

}
