<div id="password-reset" class="bgr-shapes">
    <h2>Account Password Reset</h2>
    <div class="form-actions">
        <form id="reset-form" (ngSubmit)="onSubmit()" [formGroup]="resetForm" *ngIf="!resetComplete">
            <p>
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="E-mail" name="email" formControlName="email" id="email" email required>
                </mat-form-field>
            </p>
            <div class="error" *ngIf="resetForm.controls['email'].invalid && (resetForm.controls['email'].dirty || resetForm.controls['email'].touched)" class="alert alert-danger">
                <div *ngIf="resetForm.controls['email'].errors.required">
                    Please enter your email.
                </div>
                <div *ngIf="resetForm.controls['email'].errors.email">
                    Please enter a valid email address.
                </div>
            </div>

            <div class="submit-button" [class.disabled]="resetForm.status == 'INVALID'">
                <button type="submit" class="orange-button" [disabled]="resetForm.status == 'INVALID' || sendingReset">RESET PASSWORD</button>
            </div>
        </form>
        <div class="reset-sent" *ngIf="resetComplete">
            <h3>Please check your e-mail.</h3>
            <p>If your e-mail corresponds to a user in our system we will send an e-mail with instructions to reset your password.</p>
        </div>
    </div>
</div>