import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { COOKIE_DOMAIN } from '../constants/constants';
import { ClassLoginService } from '../services/class-login.service';

@Injectable({
  providedIn: 'root'
})
export class ClassLoginGuard implements CanActivate {

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private classLoginService: ClassLoginService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    this.cookieService.deleteAll();

    const clase_uuid = route.url[1].path;

    return this.classLoginService.checkClassLoginEnable(clase_uuid).pipe(map(token => {
      const teacher_token = token['token'];
      this.cookieService.set('JWT', teacher_token, { domain: COOKIE_DOMAIN });
      this.cookieService.set('PREJWT', teacher_token, { domain: COOKIE_DOMAIN });
      return true;
    }),
      catchError((err) => {
        console.log(err.error);
        alert(err.error['error']);
        this.cookieService.deleteAll();
        this.router.navigate(['/']);
        return of(false);
      }));

  }

}
