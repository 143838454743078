import { Injectable } from '@angular/core';
import { Product } from '../classes/product';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  items: Product[] = [
  ];
  totalPrice: number;

  addToCart(product: Product) {
    this.items.push(product);
    this.calculateTotalPrice();
  }

  removeFromCart(unique_id: string) {
    let c = 0;
    for (let item of this.items) {
      if (item.unique_id == unique_id) {
          this.items.splice(c, 1);
      }
      c+=1;
    }
    this.calculateTotalPrice();
  }

  getItems() {
    return this.items;
  }

  clearCart() {
    this.items = [];
    this.calculateTotalPrice();
    return this.items;
  }

  isInCart(unique_id: string): boolean {
    for (let item of this.items) {
      if (item.unique_id == unique_id) {
        return true;
      }
    }
    return false;
  }

  updateItemQuantity(unique_id: string, quantity: number) {
    for (let item of this.items) {
      if (item.unique_id == unique_id) {
        item.quantity = quantity;
      }
    }
    this.calculateTotalPrice();
  }

  calculateTotalPrice() {
    this.totalPrice = 0;
    for (let item of this.items) {
      this.totalPrice += item.price*item.quantity;
    }
    this.totalPrice = Math.round((this.totalPrice + Number.EPSILON) * 100) / 100;
  }

  // FUNCTION TO DETECT TYPE OF ITEMS PRESENT IN THE CART
  typeInCart(type: string): boolean {
    for (let item of this.items) {
      if (item.category.toLowerCase() == type) {
        return true
      }
    }
    return false;
  } 
}