import { Injectable } from '@angular/core';
import { License } from '../classes/license';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  lightBgr: boolean = false;
  activePage: string = "";
  menuOpen: boolean = false;

  checkoutSuccessful: boolean = false;
  receipt_url: string = "";

  licenses: Array<License> = [
    {
      'title': 'Maxguru K-12',
      'price': 299.99,
      'description': 'MaxGuru license - ONE YEAR LICENSE',
      'long_description': 'Our full suite, including Placement Test, MaxPhonics, MaxReading, MaxWords, MaxMusic, MaxPlaces, MaxBios, and MaxVocab, plus a parent license',
      'id': 30,
      'img': '/assets/img/maxguru.svg',
      'alt_img': 'homeschool programs for dyslexia'
    },
    {
      'title': 'K-2',
      'price': 99.99,
      'description': 'K-2 license - ONE YEAR LICENSE',
      'long_description': 'Geared toward younger children, K-2 includes Placement test, MaxPhonics, MaxReading, plus a parent license',
      'id': 31,
      'img': '/assets/img/k2.svg',
      'alt_img': 'K-2 online programs'
    },
    {
      'title': 'Early Start',
      'price': 79.99,
      'description': 'Early Start license - ONE YEAR LICENSE',
      'long_description': 'Placement test, MaxPhonics, and a parent license',
      'id': 32,
      'img': '/assets/img/early.svg',
      'alt_img': 'orton gillingham online programs'
    }
  ]
  
  constructor() { }

  getLicense(id: number): License|null {
    for (let license of this.licenses) {
      if (license.id == id) {
        return license;
      }
    }
    return null;
  }
}
