<section class="main-section first-section" id="class-login">
</section>

<div class="loading-screen" *ngIf="loadingScreen">
  <mat-spinner class="loading-spinner"></mat-spinner>
</div>


<div class="container class-login-container">
  <div class="row class-login-header">
    <p>Welcome to {{ class_name }} Simple Class Login</p>
    <p>CLICK ON YOUR NAME TO START USING THE PROGRAM</p>
  </div>
  <div class="row">
    <div class="col-3 mat-card-col" *ngFor="let student of students">
      <mat-card class="mat-card">
        <mat-card-subtitle *ngIf="student.last_name">{{ student.last_name }}, {{ student.first_name }}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="!student.last_name"><span>&#8203;</span></mat-card-subtitle>
        <mat-card-title class="mat-card-title-text"> Username: {{ student.username }} </mat-card-title>
        <mat-card-content>
          <p *ngIf="student.email">Email: {{ student.email }}</p>
          <p *ngIf="!student.email"><span>&#8203;</span></p>
        </mat-card-content>
        <mat-divider inset></mat-divider>
        <mat-card-actions>
          <button mat-button (click)="loginStudent(student)" id="{{ student.username }}" class="btn btn-primary mat-card-btn">Login as {{ student.username|slice:0:8 }}...</button>
        </mat-card-actions>

      </mat-card>

    </div>
  </div>

