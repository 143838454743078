import { Component, OnInit } from '@angular/core';
import { Training } from '../classes/training';
import { DataService } from '../services/data.service';
import { GlobalsService } from '../services/globals.service';
import * as moment from 'moment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  og_trainings: Array<Training> = [];

  ow_trainings: Array<Training> = [];

  ist_training: Training;

  ist_trainings: Array<Training> = [];

  constructor(
    private globals: GlobalsService,
    private dataService: DataService,
    private meta: Meta,
    private title: Title
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "training";
    }, 5);
    this.title.setTitle("Orton-Gillingham Training for Teachers | MaxScholar");
    this.meta.addTags([
      { name: "title", content: "Orton-Gillingham Training for Teachers | MaxScholar" },
      { name: "keywords", content: "Orton-Gillingham Training, Orton-Gillingham Certification, Orton-Gillingham Teacher Training, Orton-Gillingham Training for Teachers" },
      { name: "description", content: "Our Orton-Gillingham training and certification provides an in-depth understanding of the MaxScholar Orton-Gillingham approach. Buy our OG Training today." }
    ])

    this.ist_training = new Training();
    this.ist_training.title = "Initial Software Training";
    this.ist_training.kind = "IST";
    this.ist_training.description = "TEMP DESCRIPTION";



    this.dataService.getTrainingCourses().subscribe((response: Object)=>{
      console.log(response);
      let _response =  Object.values(response);

      console.log(_response);
      let sortedTrainings = _response.sort((a,b) => {
        try {
          let momentA: moment.Moment = moment(a.date.substring(0, a.date.indexOf("-")-1), "MMMM DD, YYYY");
          let momentB: moment.Moment = moment(a.date.substring(0, a.date.indexOf("-")-1), "MMMM DD, YYYY");
          let diff = <any>momentA - <any>momentB;
          return diff;
        } catch(error) {
          console.log("DATE PARSING ERROR", error)
          return 0;
        }
      })
      console.log(sortedTrainings)
      sortedTrainings.forEach((_training) => {
        let date: moment.Moment = moment(_training.date.substring(0, _training.date.indexOf("-")-1), "MMMM DD, YYYY");
        // TODO HANDLE POSSIBLE INVALID DATES, since ANYTHING CAN BE ENTERED ON DATE, LIKE 'to be defined'
        if (date >= moment() || _training.kind == "IST") {
          if (_training.kind == "OG") {
            this.og_trainings.push(_training);
          } else if (_training.kind == "OW") {
            this.ow_trainings.push(_training);
          } else if (_training.kind == "IST") {
            this.ist_trainings.push(_training);
          }

        }
      });
    });
  }

}
