import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { SERVICE_CORE_SCHOOL_API_DOMAIN, SERVICE_AUTHENTICATION_API_DOMAIN } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ClassLoginService {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) { }

  private createAuthorizationHeader(): HttpHeaders {

    const jwttoken = this.cookieService.get('JWT');
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')
    .set('Authorization', `JWT ${jwttoken}`);

    return headers;

  }


  public checkClassLoginEnable(clase_uuid: string): Observable<any> {
    return this.httpClient.get(SERVICE_CORE_SCHOOL_API_DOMAIN + `/school/clase/${clase_uuid}/`);
  }

  public getStudentsClassLogin(clase_id: string): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.httpClient.get(SERVICE_CORE_SCHOOL_API_DOMAIN+`/school/clase/${clase_id}/student/`, {
      headers: headers
    });
  }

  public doClassLogin(username: string): Observable<any> {
    const headers = this.createAuthorizationHeader();
    const data = {
      "username": username
    }
    return this.httpClient.post(SERVICE_AUTHENTICATION_API_DOMAIN+"/auth/class_login/", data, {
      headers: headers,
    });
  }
}
