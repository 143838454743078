<section class="reviews">
    <div class="reviews-content">
        <img class="reviews-quote" src="assets/img/quote.png" alt="Quote" />
        <div class="reviews-card">
            <ngb-carousel #carousel id="reviews-slider" [interval]="0" [animation]="true">

                <ng-template *ngFor="let review of reviews" ngbSlide>

                        <div class="reviews-box">
                            <p class="reviews-text" [innerHTML]="review.text"></p>
                            <p class="reviews-info">
                                <b class="reviews-name">{{review.name}}</b><br />
                                <i class="reviews-office">{{review.office}}</i><br />
                            </p>
                        </div>

                </ng-template>
        
            </ngb-carousel>
        </div>
    </div>
</section>