import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-methods',
  templateUrl: './methods.component.html',
  styleUrls: ['./methods.component.scss']
})
export class MethodsComponent implements OnInit {

  constructor(
    private globals: GlobalsService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "support";
    }, 5);
  }
}
