import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { QuoteComponent } from './quote/quote.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { SoftwareComponent } from './software/software.component';
import { WorkbooksEbooksComponent } from './workbooks-ebooks/workbooks-ebooks.component';
import { BookComponent } from './book/book.component';
import { HttpClientModule } from '@angular/common/http';
import { ContactComponent } from './contact/contact.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import  { MatInputModule} from '@angular/material/input'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CartWidgetComponent } from './cart-widget/cart-widget.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";

import { SafePipe } from './pipes/safe.pipe';
import { HomeLicensesComponent } from './home-licenses/home-licenses.component';
import { SupportComponent } from './support/support.component';
import { TrainingComponent } from './training/training.component';
import { TrainingItemComponent } from './training-item/training-item.component';
import { CheckoutItemComponent, ConfirmDialog } from './checkout-item/checkout-item.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SchoolLicensesComponent } from './school-licenses/school-licenses.component';
import { TutoringComponent } from './tutoring/tutoring.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RedirectGuard } from './guard/redirect-guard.guard';
import { PasswordResetSubmissionComponent } from './password-reset-submission/password-reset-submission.component';
import { LearningDisabilitiesComponent } from './learning-disabilities/learning-disabilities.component';
import { MethodsComponent } from './methods/methods.component';
import { DoeFundingComponent } from './doe-funding/doe-funding.component';
import { CheckUserComponent } from './check-user/check-user.component';
import { JWTService } from './services/jwt.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { ClassLoginComponent, ConfirmClassLoginDialog } from './class-login/class-login.component';
import { PurchaseSuccessComponent } from './purchase-success/purchase-success.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    QuoteComponent,
    ReviewsComponent,
    SoftwareComponent,
    WorkbooksEbooksComponent,
    BookComponent,
    ContactComponent,
    CartWidgetComponent,
    CheckoutComponent,
    PaymentFormComponent,
    SafePipe,
    HomeLicensesComponent,
    SupportComponent,
    TrainingComponent,
    TrainingItemComponent,
    CheckoutItemComponent,
    ConfirmDialog,
    ConfirmClassLoginDialog,
    SchoolLicensesComponent,
    TutoringComponent,
    LoginComponent,
    PasswordResetComponent,
    PasswordResetSubmissionComponent,
    LearningDisabilitiesComponent,
    MethodsComponent,
    DoeFundingComponent,
    CheckUserComponent,
    ClassLoginComponent,
    PurchaseSuccessComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectCountryModule.forRoot('en'),
    MatProgressBarModule
  ],
  providers: [RedirectGuard, JWTService],
  bootstrap: [AppComponent]
})
export class AppModule { }
