import { Component, OnInit } from '@angular/core';
import { CartService } from '../services/cart.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'cart-widget',
  templateUrl: './cart-widget.component.html',
  styleUrls: ['./cart-widget.component.scss']
})
export class CartWidgetComponent implements OnInit {

  constructor(
    public cartService: CartService,
    public globals: GlobalsService
  ) { }

  ngOnInit(): void {
  }

}
