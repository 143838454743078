<section class="main-section first-section" id="contact">
</section>
<div class="bgr-shapes">
    <section id="contact-form">
        <div class="content">
            <h3 class="subtitle">Share you feedback</h3>
            <h1>Contact Form</h1>
            <p>Share your feedback or ask any questions</p>

            <div *ngIf="!contactSent" class="contact-container">
                <h3 class="subtitle">Get in touch. We would love to hear from you.</h3>
                <h3 class="subtitle sub">Please submit the form below, and we will answer your questions.</h3>
                <form (ngSubmit)="onSubmit()" #contactForm="ngForm" [formGroup]="form">
                    <div class="form-group container">
                        <div class="row">
                            <div class="col-md">
                                <p>
                                    <mat-form-field>
                                        <mat-label>Full Name</mat-label>
                                        <input matInput placeholder="name" name="name" formControlName="name" id="name" required>
                                    </mat-form-field>
                                </p>
                                <div class="error" *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)" class="alert alert-danger">
                                    <div *ngIf="form.controls['name'].errors.required">
                                    Name is required.
                                </div>
                                </div>
                                <p>
                                    <mat-form-field>
                                        <mat-label>Phone Number</mat-label>
                                        <input matInput placeholder="phone" name="phone" formControlName="phone"  id="phone" required>
                                    </mat-form-field>
                                </p>
                                <div *ngIf="form.controls['phone'].invalid && (form.controls['phone'].dirty || form.controls['phone'].touched)" class="alert alert-danger">
                                    <div *ngIf="form.controls['phone'].errors.required">
                                    Phone is required.
                                    </div>
                                    <div *ngIf="form.controls['phone'].errors.pattern">
                                        Please enter a valid phone number.
                                    </div>
                                </div>
                                <p>
                                    <mat-form-field>
                                        <mat-label>Email Address</mat-label>
                                        <input matInput placeholder="email" name="email" formControlName="email"  id="email" email required>
                                    </mat-form-field>
                                </p>
                                <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="alert alert-danger">
                                    <div *ngIf="form.controls['email'].errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="form.controls['email'].errors.email">
                                        Please enter a valid email address.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <p>
                                    <mat-form-field>
                                        <mat-label>Message</mat-label>
                                        <textarea matInput rows="10" placeholder="message" formControlName="comments" name="comments" id="comments" required></textarea>
                                    </mat-form-field>
                                </p>
                                <div *ngIf="form.controls['comments'].invalid && (form.controls['comments'].dirty || form.controls['comments'].touched)" class="alert alert-danger">
                                    <div *ngIf="form.controls['comments'].errors.required">
                                    Comment is required.
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="submit-button" [class.disabled]="form.status == 'INVALID'">
                            <button type="submit" class="orange-button" [disabled]="form.status == 'INVALID' || sending">SEND MESSAGE</button>
                        </div>

                    </div>

                </form>
            </div>
            <div *ngIf="contactSent"  class="contact-container">
                <h3 class="subtitle sent">Thank you for your message!</h3>
            </div>
        </div>
    </section>
</div>