<div id="password-reset" class="bgr-shapes">
    <h2>Account Password Reset</h2>
    <div class="form-actions">
        <form id="reset-form" (ngSubmit)="onSubmit()" [formGroup]="resetForm" *ngIf="!resetComplete">
            <p>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput placeholder="Password" type="password" name="password" formControlName="password" id="password" password required>
                </mat-form-field>
            </p>
            <div class="error" *ngIf="resetForm.controls['password'].invalid && (resetForm.controls['password'].dirty || resetForm.controls['password'].touched)" class="alert alert-danger">
                <div *ngIf="resetForm.controls['password'].errors.required">
                    Please enter your password.
                </div>
            </div>
            <p>
                <mat-form-field>
                    <mat-label>Re-enter password</mat-label>
                    <input matInput placeholder="Re-enter password" type="password" name="rePassword" formControlName="rePassword" id="rePassword" password>
                </mat-form-field>
            </p>
            <div class="error" *ngIf="resetForm.controls['rePassword'].invalid && (resetForm.controls['rePassword'].dirty || resetForm.controls['rePassword'].touched)" class="alert alert-danger">
                <div *ngIf="resetForm.controls['rePassword'].errors.required">
                    Please re-enter your password.
                </div>
            </div>
            <div class="error" *ngIf="(resetForm.controls['rePassword'].dirty || resetForm.controls['rePassword'].touched) && resetForm.errors?.invalid" class="alert alert-danger">
                <span>
                    Password doesn't match.
                </span>
            </div>

            <div class="submit-button" [class.disabled]="resetForm.status == 'INVALID'">
                <button type="submit" class="orange-button" [disabled]="resetForm.status == 'INVALID' || sendingReset">RESET PASSWORD</button>
            </div>
        </form>
        <div class="reset-sent" *ngIf="resetComplete">
            <h3>Your password has been reset.</h3>
            <p>You can <button routerLink="/login" class="orange-button">LOG IN</button> with your new password now.</p>
        </div>
    </div>
</div>
