<section class="main-section first-section" id="home-licenses">
    <div class="content">
        <h1>MaxScholar Home Software</h1>
    </div>
</section>
<section id="licenses-buy" class="container">
    <div class="row">
        <div class="col-4">
            <div class="license">
                <img src="{{licenses[0].img}}" alt="{{ licenses[0].alt_img }}" />
                <h3>{{licenses[0].title}}</h3>
                <h1 class="price">${{licenses[0].price}}</h1>
                <h3 class="subtitle">ONE-YEAR LICENSE</h3>
                <!-- previously buyLicense(0) to directly buy -->
                <div (click)="freeTrialLicense(licenses[0].id)"  class="orange-button">15-DAY FREE TRIAL</div>
                <ul>
                    <li>Built-in placement test in phonics and reading comprehension to assess the student's
                        phonological foundation and reading comprehension level.</li>
                    <li>Full access to all MaxScholar programs for one year:
                        <ul>
                            <li>MaxPhonics: phonics, phonemic awareness, sound blending, fluency, sight words, and more.
                            </li>
                            <li>MaxReading: reading, highlighting, comprehension, summarizing, writing, vocabulary, and
                                more.</li>
                            <li>MaxWords: syllabication, spelling rules, prefixes, suffixes, roots.</li>
                            <li>Additional programs for drill and practice: MaxMusic, MaxPlaces, MaxBios, MaxVocab</li>
                        </ul>
                    </li>
                    <li>FREE parent license with access to reports and printable resources.</li>
                </ul>
            </div>
        </div>
        <div class="col-4">
            <div class="license">
                <img src="{{licenses[1].img}}" alt="{{licenses[1].alt_img}}" />
                <h3>{{licenses[1].title}}</h3>
                <h1 class="price">${{licenses[1].price}}</h1>
                <h3 class="subtitle">ONE-YEAR LICENSE</h3>
                <div (click)="freeTrialLicense(licenses[1].id)" class="orange-button">15-DAY FREE TRIAL</div>
                <ul>
                    <li>Built-in placement test in phonics and reading comprehension to assess the student's
                        phonological foundation and reading comprehension level.</li>
                    <li>Full access to all MaxScholar programs for one year:
                        <ul>
                            <li>MaxPhonics: phonics, phonemic awareness, sound blending, fluency, sight words, and more.
                            </li>
                            <li>MaxReading: reading, highlighting, comprehension, summarizing, writing, vocabulary, and
                                more.</li>
                        </ul>
                    </li>
                    <li>FREE parent license with access to reports and printable resources.</li>
                </ul>
            </div>
        </div>
        <div class="col-4">
            <div class="license">
                <img src="{{licenses[2].img}}" alt="{{ licenses[2].alt_img }}" />
                <h3>{{licenses[2].title}}</h3>
                <h1 class="price">${{licenses[2].price}}</h1>
                <h3 class="subtitle">ONE-YEAR LICENSE</h3>
                <div (click)="freeTrialLicense(licenses[2].id)" class="orange-button">15-DAY FREE TRIAL</div>
                <ul>
                    <li>Built-in placement test in phonics and reading comprehension to assess the student's
                        phonological foundation and reading comprehension level.</li>
                    <li>Full access to the MaxPhonics program (consonants, vowels, blends, and digraphs) for one year.
                        Includes phonemic awareness, sound blending, fluency, sight words, and more.
                    </li>
                    <li>FREE parent license with access to reports and printable resources.</li>
                </ul>
            </div>
        </div>
    </div>
</section>