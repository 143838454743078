import { environment } from "src/environments/environment";

export const API_DOMAIN = environment.api_domain;
export const MASTERCODE_API_DOMAIN = environment.mastercode_api_domain;
export const SERVICE_NO_AUTH_API_DOMAIN = environment.service_no_auth_api_domain;
export const SERVICE_AUTHENTICATION_API_DOMAIN = environment.service_authentication_api_domain;
export const SERVICE_CORE_SCHOOL_API_DOMAIN = environment.service_core_school;
export const APP_FRONTEND_URL = environment.app_frontend_url;
export const DASHBOARD_FRONTEND_URL = environment.dashboard_frontend_url;
export const COOKIE_DOMAIN = environment.cookie_domain;
export const ADMIN_URL = environment.admin_url;
