import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-purchase-success',
  templateUrl: './purchase-success.component.html',
  styleUrls: ['./purchase-success.component.scss']
})
export class PurchaseSuccessComponent implements OnInit {

  constructor(
    private globals: GlobalsService
  ) { }

  ngOnInit(): void {
    this.globals.checkoutSuccessful = false;

    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "cart";
    }, 5);
  }

}
