<section class="main-section" id="home">
    <ngb-carousel #carousel id="home-slider" [interval]="3000" [pauseOnHover]="false" [animation]="true" (slide)="onSlide($event)">

        <ng-template id="slide_1" ngbSlide>
            <section class="slide slide1 first-section">
                <div class="content">
                    <div class="text">
                        <h1 class="slide-1-h1">MAXSCHOLAR <br>Software</h1>
                        <ul class="slide-1-list">
                          <li>Does your child <b>struggle with reading and language</b>?</li>
                          <li>Is school becoming a <b>real challenge</b>?</li>
                          <li>Could they use skills to <b>improve their grades</b>?</li>
                        </ul>
                        <p>Maxscholar software provides comprehensive and multisensory Orton-Gillingham phonics and reading programs.</p>
                        <p>For families and schools.</p>
                        <button class="orange-button" routerLink="/login">FREE DEMO</button>
                    </div>
                    <div class="image">
                        <img src="assets/img/maxscholar-home.png" alt="Online reading intervention programs" />
                    </div>
                </div>
            </section>
        </ng-template>
        <ng-template id="slide_2" ngbSlide>
            <section class="slide slide2 first-section">
                <div class="content">
                    <div class="text">
                        <h1 style="font-size:40px;">MAXSCHOLAR <br>Orton-Gillingham <br>Training & Certification</h1>
                        <br>
                        <ul>
                          <li>Learn to <b>teach the Orton-Gillingham Method</b></li>
                          <li>Training <b>in-person or via webinar</b></li>
                          <li>Full <b>OG Certification</b> with certificate</li>
                        </ul>
                        <p class="slide-2-p">Our training courses brings this Multi-sensory Approach to the Modern Classroom.</p>
                        <button class="orange-button" routerLink="/og-training">LEARN MORE</button>
                    </div>
                    <div class="image og-main-image">
                        <img src="assets/img/maxscholar-training-certification.png"
                            alt="orton gillingham certification" />
                    </div>
                </div>

            </section>
        </ng-template>
        <ng-template id="slide_3" ngbSlide>
            <section class="slide slide3 first-section left-aligned-header">
                <div class="content">
                    <div class="text">
                        <h1 class="workbook-materials-h1">Workbooks <br>& Materials</h1>
                        <p class="workbook-materials-p">Explore our different products to help your students <b>improve their reading skills!</b></p>
                        <ul>
                          <li>Workbooks</li>
                          <li>Teacher Manuals</li>
                          <li>Manipulatives</li>
                          <li>eBooks</li>
                        </ul>
                        <button routerLink="/workbooks-ebooks" class="orange-button">LEARN MORE</button>
                    </div>
                </div>

            </section>
        </ng-template>
        <ng-template id="slide_4" ngbSlide>
            <section class="slide slide4 first-section left-aligned-header">
                <div class="content">
                    <div class="text">
                        <h1>One-on-One Tutoring</h1>
                        <ul>
                          <li style="line-height: 1.25;">Does your child struggle with <b>Dyslexia, ADHD, Dysgraphia, or Visual/Auditory <br>
                          processing disorders?</b></li>
                          <li>Do you want to <b>improve</b> your child's skills up to <b>TWO grade levels</b>?</li>
                          <li>Does your child need <b>one-on-one private tutoring</b>?</li>
                        </ul>
                        <p>We at Maxscholar provide tutoring to individual students and schools through government grants and funds.</p>
                        <button class="orange-button" routerLink="/contact">CONTACT US</button> <span style="padding-left:15px;">FREE assessment!</span>
                    </div>
                </div>
            </section>
        </ng-template>
    </ngb-carousel>
    <div class="carousel-decoration"></div>
</section>
<section id="sub-text">
    <div class="content">
        <p>MaxScholar's Orton-Gillingham based Phonics and Reading Comprehension programs use research-based methods to
            help students improve their reading, writing, spelling, and comprehension abilities.</p>
    </div>
</section>
<section id="home-sections">
    <div class="content container">
        <div class="row">
          <div class="col-sm section-container">

            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <img src="assets/img/maxscholar-home.png" />
                    </div>
                    <div class="col-6 right">
                        <h2>MAXSCHOLAR
                            Software</h2>
                        <button routerLink="/software" class="orange-button">LEARN MORE</button>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            Our browser-based software uses an Orton-Gillingham blended-learning environment to build
                            reading, writing, and reading comprehension skills for all students. It was specifically
                            created to help struggling readers, students in Special Education programs, English language
                            learners, and students in RTI programs.
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-sm section-container">

                <div class="container">
                    <div class="row">
                        <div class="col-6">
                            <img src="assets/img/og-training.png" alt="orton gillingham training" />
                        </div>
                        <div class="col-6 right">
                            <h2>OG Training &
                                Certification</h2>
                            <button routerLink="/og-training" class="orange-button">LEARN MORE</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            Our Orton-Gillingham (OG) <strong>training and certification</strong> courses prepare
                            teachers to implement this approach with their students.
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm section-container">

                <div class="container">
                    <div class="row">
                        <div class="col-6">
                            <img src="assets/img/one-on-one-tutoring.jpg" alt="orton gillingham programs" />
                        </div>
                        <div class="col-6 right">
                            <h2>One-on-One
                                Tutoring</h2>
                            <button class="orange-button" routerLink="/tutoring">LEARN MORE</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            We now offer one-on-one remote tutoring using our Orton-Gillingham approach. Our OG trained teachers can help your child SOAR! This is especially valuable for students who have dyslexia.
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-sm section-container">

                <div class="container">
                    <div class="row">
                        <div class="col-6">
                            <img src="assets/img/workbooks.png" alt="orton gillingham workbooks" />
                        </div>
                        <div class="col-6 right">
                            <h2>Workbooks &
                                Materials</h2>
                            <button routerLink="/workbooks-ebooks" class="orange-button">LEARN MORE</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            Our workbooks & Orton-Gillingham materials are an extension of the software that will
                            <strong>help students learn</strong>, and reinforce, all the skills learned in the different
                            programs in MaxScholar.
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
<section id="home-video">
    <video poster="assets/img/home-video-thumb.jpg" #videoPlayer>
        <source src="https://backend.maxscholar.com/media/no-auth/videos/maxscholar-success-story.mp4" type="video/mp4">
    </video>
    <div class="play-action" *ngIf="!videoPlaying" (click)="playPauseVideo()">
        <h3>School testing results success with
            MaxScholar Orton-Gillingham Software</h3>
        <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M112.597 81.1452L55.984 113.831L55.984 48.4597L112.597 81.1452Z" fill="white" />
            <circle cx="78" cy="78" r="70" stroke="white" stroke-width="16" />
        </svg>
        <h4>Watch the video</h4>
    </div>


<quote></quote>

<section id="clever">
    <div class="clever-box">
        <span>Partnered with</span>
        <svg width="258" height="71" viewBox="0 0 258 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.3476 70.9659C15.3641 70.9659 0.558228 55.6186 0.558228 36.2212V36.0507C0.558228 16.8238 15.1073 1.09277 35.9467 1.09277C48.7414 1.09277 56.401 5.35593 62.6914 11.5375L53.1917 22.3659C47.9711 17.6338 42.6222 14.7349 35.8611 14.7349C24.4358 14.7349 16.1771 24.1991 16.1771 35.7949V36.008C16.1771 47.6038 24.2219 57.2386 35.8611 57.2386C43.6064 57.2386 48.3562 54.1691 53.7052 49.3091L63.2049 58.8586C56.1871 66.3191 48.4418 70.9659 35.3476 70.9659Z"
                fill="#436CF2" />
            <path d="M79.2517 0.325195H64.5315V70.7952H79.2517V0.325195Z" fill="#436CF2" />
            <path
                d="M106.595 28.8458C100.476 28.8458 96.4967 33.1942 95.3414 39.8448H117.55C116.651 33.2795 112.8 28.8458 106.595 28.8458ZM131.671 49.0532H95.5553C97.0102 55.7037 101.674 59.1995 108.264 59.1995C113.228 59.1995 116.78 57.6648 120.845 53.8705L129.275 61.2884C124.439 67.2569 117.465 70.9232 108.05 70.9232C92.4315 70.9232 80.9207 60.0095 80.9207 44.1932V43.98C80.9207 29.1869 91.4901 17.0369 106.595 17.0369C123.926 17.0369 131.885 30.4658 131.885 45.1311V45.3016C131.885 46.7937 131.8 47.689 131.671 49.0532Z"
                fill="#436CF2" />
            <path
                d="M159.913 70.6675H146.52L126.065 18.5291H141.684L153.324 53.1885L165.048 18.5291H180.368L159.913 70.6675Z"
                fill="#436CF2" />
            <path
                d="M199.453 28.8458C193.334 28.8458 189.354 33.1942 188.199 39.8448H210.407C209.509 33.2795 205.657 28.8458 199.453 28.8458ZM224.528 49.0532H188.412C189.867 55.7037 194.532 59.1995 201.122 59.1995C206.043 59.1995 209.637 57.6648 213.702 53.8705L222.132 61.2884C217.297 67.2569 210.322 70.9232 200.908 70.9232C185.289 70.9232 173.778 60.0095 173.778 44.1932V43.98C173.778 29.1869 184.347 17.0369 199.453 17.0369C216.783 17.0369 224.742 30.4658 224.742 45.1311V45.3016C224.742 46.7937 224.657 47.689 224.528 49.0532Z"
                fill="#436CF2" />
            <path
                d="M256.536 33.45C246.737 33.45 240.746 39.3331 240.746 51.6963V70.7952H226.026V19.0405H240.746V29.4852C243.742 22.3231 248.62 17.7189 257.307 18.1026V33.45H256.536Z"
                fill="#436CF2" />
        </svg>
        <a href="https://clever.com/" target="_blank" class="orange-button">LOG IN WITH CLEVER</a>
    </div>
</section>

<section id="partners">
    <div class="content">
        <h1>OUR PARTNERS</h1>

        <div>
            <img src="assets/img/georgia-department-of-education-logo.png" alt="Georgia Department of Education Logo" />
            <img style="position: relative;left:20px;" src="assets/img/nyc-department-of-education.png"
                alt="NYC Department of Education" />
            <img src="assets/img/broward-county.jpg" alt="Broward County Public Schools" />
            <img src="assets/img/lda-logo.png" alt="Learning Disabilities Association of America" />
            <img src="assets/img/Detroit_Public_Schools_logo.svg" alt="Detroit Public Schools Logo" />
        </div>
    </div>
</section>
