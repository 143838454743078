import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { License } from '../classes/license';
import { Product } from '../classes/product';
import { CartService } from '../services/cart.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-home-licenses',
  templateUrl: './home-licenses.component.html',
  styleUrls: ['./home-licenses.component.scss']
})
export class HomeLicensesComponent implements OnInit {

  licenses: Array<License>;

  constructor(
    private globals: GlobalsService,
    private cartService: CartService,
    private router: Router,
    private meta: Meta,
    private title: Title
  ) { }

  ngOnInit(): void {
    
    this.licenses = this.globals.licenses;

    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "software";
    }, 5);
    this.title.setTitle("Orton-Gillingham Homeschool Programs | MaxScholar");
    this.meta.addTags([
      { name: "title", content: "Orton-Gillingham Homeschool Programs | MaxScholar" },
      { name: "keywords", content: "Orton-Gillingham Homeschool, Homeschool Programs for Dyslexia" },
      { name: "description", content: "Our Orton-Gillingham homeschool programs can be used on any device, allowing students to work on their reading skills at home. Buy our 1 year Home License now." }
    ])
  }

  freeTrialLicense(license_number: number) {
    this.router.navigate(['/login'], { queryParams: { licenseType: license_number } }); 
  }

  buyLicense(license_number: number) {
    let license: License = this.licenses[license_number];

    let product = new Product();
    product.title = license.title;
    product.category = 'license';
    product.description = license.title + ' license - ONE YEAR LICENSE';
    product.price = license.price;
    product.id = license.id;
    product.unique_id = product.category + "_" + license.id;
    product.cover = license.img;
    product.quantity = 1;

    this.cartService.addToCart(product);
    this.router.navigate(['checkout']);

  }

}
