<section class="main-section first-section left-aligned-header" id="section-header">
    <div class="content">
        <h1>METHODS</h1>
        <p>MaxScholar provides an effective solution to help educators achieve student success.</p>
        <button routerLink="/contact" class="orange-button">CONTACT US</button> <span style="padding-left:15px;">FREE
            assessment!</span>
    </div>
</section>

<div class="bgr-shapes">
    <section id="methods-content">
        <div class="container">
            <h3 class="subtitle">Materials</h3>
            <h1>Feel free to download and share our material</h1>
            <p>We would be more than happy to give you a personalized demo.</p>


            <div class="content-box">
                <div class="container">
                    <div class="row">
                        <div class="col-md">
                            <h2>Studies</h2>

                            <ul>
                                <li>
                                    <a href="https://backend.maxscholar.com/media/no-auth/resources/MaxScholar+Research+_+Results-+Case+Studies+2020.pdf" target="_blank">
                                        <img src="/assets/img/word-icon.png" alt="MaxScholar Case Studies" />
                                        MaxScholar Case Studies 2020.docx
                                    </a>
                                </li>
                                <li>
                                    <a href="https://backend.maxscholar.com/media/no-auth/resources/MaxScholar Research _ Results- MaxScholar Research Information Book.pdf" target="_blank">
                                        <img src="/assets/img/pdf-icon.png" alt="MaxScholar Research" />
                                        MaxScholar Research Information Book.pdf
                                    </a>
                                </li>
                                <li>
                                    <a href="https://backend.maxscholar.com/media/no-auth/resources/Software info_ MaxScholar sample reports.pdf" target="_blank">
                                        <img src="/assets/img/pdf-icon.png" alt="MaxScholar Sample" />
                                        MaxScholar Sample Reports.pdf
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md">
                            <h2>Software</h2>

                            <ul>
                                <li><a href="https://backend.maxscholar.com/media/no-auth/resources/Software info_ MaxScholar Leveled Software.xlsx"
                                        target="_blank"><img src="/assets/img/excel-icon.png"
                                            alt="MaxScholar Leveled" /> MaxScholar Leveled
                                        Software.xlsx</a></li>
                                <li><a href="https://backend.maxscholar.com/media/no-auth/resources/Software info_ MaxScholar Software Comparison.pdf"
                                        target="_blank"><img src="/assets/img/pdf-icon.png" alt="MaxScholar software" />
                                        MaxScholar Software
                                        Comparison - Scope and Sequence.pdf</a></li>
                                <li><a href="https://backend.maxscholar.com/media/no-auth/resources/MaxScholar Sole Source Provider Letter 2020.docx"
                                        target="_blank"><img src="/assets/img/word-icon.png"
                                            alt="MaxScholar Sole Source" /> MaxScholar Sole Source
                                        Provider Letter 2020.docx</a></li>
                                <li><a href="https://backend.maxscholar.com/media/no-auth/resources/Software info_ SAMPLE PRETEST.pdf"
                                        target="_blank"><img src="/assets/img/pdf-icon.png"
                                            alt="MaxScholar SAMPLE PPRETEST" /> SAMPLE PPRETEST.pdf</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <h2>Institutional</h2>
                            <ul>
                                <li><a href="https://backend.maxscholar.com/media/no-auth/resources/Purchasing_ MaxScholar Catalog.pdf"
                                        target="_blank"><img src="/assets/img/pdf-icon.png" alt="MaxScholar software" />
                                        MaxScholar
                                        Catalog.pdf</a></li>
                                <li><a href="https://backend.maxscholar.com/media/no-auth/resources/MaxScholar Research _ Results- Booklet.pdf"
                                        target="_blank"><img src="/assets/img/pdf-icon.png" alt="MaxScholar software" />
                                        MaxScholar
                                        Booklet.pdf</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <h1 class="sub">OUR MODEL</h1>

            <div class="container methods-description">
                <div class="row">
                    <div class="col-md">
                        <h3><span class="num">1.</span> Let’s start working together!</h3>
                        <p>Once your school has decided to implement MaxScholar in the classroom, you will receive an
                            email with materials to prepare for the training. These materials include teaching
                            resources, informational documents, and a list in order to generate accounts for students
                            and teachers.</p>
                    </div>
                    <div class="col-md">
                        <h3><span class="num">2.</span> Installation & Professional Development</h3>
                        <p>Our team will go to your school in order to train the teachers that will be implementing the
                            software. We will go through all the steps of the different programs, games, and apps. This
                            is when you will receive all the usernames and passwords and will be able to practice using
                            the software and all of its components.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <h3><span class="num">3.</span> Monitoring Visits</h3>
                        <p>Our team will schedule complimentary follow-up visits to your school in order to ensure
                            fidelity and proper implementation. This will guarantee your students get the best possible
                            results out of the MaxScholar learning experience.</p>
                    </div>
                    <div class="col-md">
                        <h3><span class="num">4.</span> Customer Service</h3>
                        <p>You can always contact our customer service team with any questions, issues, or
                            recommendations. We are available on the phone, via email, or videoconference to provide
                            quick and effective service that will help the implementation process.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <h3><span class="num">5.</span> Resources</h3>
                        <p>We are continuously developing printable resources that can be used by educators to enhance
                            the effectiveness of our programs, games, and apps. These materials are based on experience
                            and feedback of our biggest collaborators: schools, teachers, and students.</p>
                    </div>
                    <div class="col-md"></div>
                </div>
            </div>
        </div>
    </section>
</div>