<h2 *ngIf="kind == 'OW'" mat-dialog-title>Confirm your MaxScholar account</h2>
<h2 *ngIf="kind == 'IST'" mat-dialog-title>Confirm your school code and pick a date</h2>

<p class="error" *ngIf="error">
    An error has occurred, please <button class="orange-button" (click)="contact()">CONTACT US</button> if the error persists.
</p>


<mat-dialog-content>
    <form id="trianing_form" #trainingForm="ngForm" [formGroup]="form">
        <div *ngIf="kind == 'OW'">
            <p>
                <mat-form-field>
                    <input matInput
                        placeholder="Username"
                        required
                        formControlName="username">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <input matInput
                        placeholder="Password"
                        type="password"
                        required
                        formControlName="password">
                </mat-form-field>
            </p>
        </div>
        <div *ngIf="kind == 'IST'">
            <p>
                <mat-form-field>
                    <input matInput
                        placeholder="Code"
                        type="code"
                        required
                        formControlName="code">
                </mat-form-field>
            </p>

            <p>
                <mat-form-field>
                    <input formControlName="date" matInput (focus)="picker.open()" [matDatepicker]="picker" [matDatepickerFilter]="weekendFilter" [min]="minDate"  placeholder="Choose a date" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field>
                    <mat-label>Time Zone</mat-label>
                    <select formControlName="timezone" matNativeControl required>
                    <option value="ET">Eastern Time</option>
                    <option value="CT">Central Time</option>
                    <option value="MT">Mountain Time</option>
                    <option value="PT">Pacific Time</option>
                    </select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Time</mat-label>
                    <select formControlName="time" matNativeControl required>
                    <option value="08:00">8:00 AM / 11:00 AM</option>
                    <option value="09:00">9:00 AM / 12:00 PM</option>
                    <option value="10:00">10:00 AM / 1:00 PM</option>
                    <option value="11:00">11:00 AM / 2:00 PM</option>
                    <option value="12:00">12:00 PM / 3:00 PM</option>
                    <option value="13:00">1:00 PM / 2:00 PM</option>
                    <option value="14:00">2:00 PM / 5:00 PM</option>
                    </select>
                </mat-form-field>
            </p>

            <p class="contact-different">
                If you want a different date or time <button class="green-button" (click)="contact()">CONTACT US</button>
            </p>
        </div>
    </form>

</mat-dialog-content>


<mat-dialog-actions>
    <button class="orange-button" (click)="check()" [disabled]="!form.valid || sending">Check</button>

    <button class="orange-inverse-button"(click)="close()">Close</button>
</mat-dialog-actions>