import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from '../services/data.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  resetForm: FormGroup;

  resetComplete: boolean = false;
  sendingReset: boolean = false;

  constructor(
    private globals: GlobalsService,
    private dataService: DataService,
    private fb: FormBuilder
  ) { 
    this.resetForm = this.fb.group({
      email: [null]
    });
    
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "login";
    }, 5);
  }

  onSubmit() {
    this.sendingReset = true;

    var formData: any = new FormData();
    formData.append("email", this.resetForm.get('email')!.value);

    this.dataService.resetPassword(formData).subscribe(()=> {
      this.resetComplete = true;
    }, (error) =>{
      if (error.status == 400) {
        // DON'T SHOW ERROR IN CASE OF EMAIL ALREADY EXISTS TO AVOID BRUTE FORCE ATTACKS
        this.resetComplete = true;
      } else {
        alert("Sorry, an error has ocurred.")
        this.sendingReset = false;
      }
    })
  }
}
