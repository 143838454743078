<section class="main-section first-section left-aligned-header" id="school-licenses">
    <div class="content">
        <h1>MAXCHOLAR Software in schools</h1>
        <p>We are now able to provide one-on-one tutoring to individual students in schools who have specific government
            grants and funds. </p>
        <button routerLink="/contact" class="orange-button">CONTACT US</button>
    </div>
</section>

<div class="bgr-shapes">


    <section id="school-licenses-content">
        <div class="container">

            <h1>MaxGuru School</h1>
            <p>Orton-Gillingham Software for Children K-12</p>

            <div class="content school-content">
                <div class="row">
                    <div class="col-5">
                        <img src="/assets/img/school-licenses-img-1.jpg"
                            alt="orton gillingham reading programs for school" />
                    </div>
                    <div class="col-7">
                        <div class="content-box">
                            <ul class="orange-bullet-list">
                                <li class="no-bullet" style="font-size:1.8em;">As low as $10 per student*</li>
                                <li>Built-in placement test in phonics and reading comprehension to determine the
                                    student’s individual needs and starting level.</li>
                                <li>Professional development and customer support.</li>
                                <li>Teacher license with access to reports, settings customization, and printable
                                    resources.</li>
                            </ul>
                            <button class="orange-button" routerLink="/contact">CONTACT US</button>

                            <div class="note">* for elegible orders</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content school-content">
                <div class="row">
                    <div class="col-7">
                        <div class="content-box">
                            <ul class="orange-bullet-list">
                                <li>Full access to all MaxScholar programs:</li>
                                <li><strong>MaxPhonics:</strong> phonics, phonemic awareness, sound blending, fluency,
                                    sight words, and more.</li>
                                <li><strong>MaxReading:</strong> reading, highlighting, comprehension, summarizing,
                                    writing, vocabulary, and more.</li>
                                <li><strong>MaxWords:</strong> syllabication, spelling rules, prefixes, suffixes, roots,
                                    and more.</li>
                                <li>Additional programs for drill and practice:<br>
                                    <strong>MaxMusic, MaxPlaces, MaxBios, MaxVocab.</strong>
                                </li>
                            </ul>
                            <button class="orange-button" routerLink="/contact">CONTACT US</button>
                        </div>
                    </div>

                    <div class="col-5">
                        <img src="/assets/img/school-licenses-img-2.jpg" alt="og reading program for school" />
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>