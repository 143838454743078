<section class="main-section first-section" id="login">
    <div class="content container">
        <div class="row">
            <div class="col-5">
                <h1>Log In</h1>
                <form id="login-form" (ngSubmit)="loginSubmit()" [formGroup]="loginForm">
                    <p>
                        <mat-form-field>
                            <mat-label>Username</mat-label>
                            <input matInput placeholder="username" name="username" formControlName="username" id="username" required>
                        </mat-form-field>
                    </p>
                    <div class="error" *ngIf="loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)" class="alert alert-danger">
                        <div *ngIf="loginForm.controls['username'].errors.required">
                        Please enter your username.
                        </div>
                    </div>
                    <p>
                        <mat-form-field>
                            <mat-label>Password</mat-label>
                            <input matInput placeholder="password" name="password" formControlName="password" type="password" id="password" required>
                        </mat-form-field>
                    </p>
                    <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)" class="alert alert-danger">
                        <div *ngIf="loginForm.controls['password'].errors.required">
                        Please enter you password.
                        </div>
                    </div>

                    <div class="submit-button" [class.disabled]="loginForm.status == 'INVALID'">
                        <button type="submit" class="green-button" [disabled]="loginForm.status == 'INVALID' || sending">LOG IN</button>
                    </div>

                    <span routerLink="/password-reset" class="forgot-password">Forgot your password?</span>

                    <div class="or"><span>OR</span></div>

                    <a href="https://clever.com/" target="_blank" class="green-button clever-button">
                        <img src="/assets/img/clever-logo.svg" />
                        Log in with Clever
                    </a>

                </form>

            </div>
            <div class="col-7">
                <h1>Don’t have an account?</h1>
                <div id="free-trial">
                    <h2>Create yours now with a <span>15-day free trial!</span></h2>
                    <h3>STUDENT INFO</h3>

                    <form id="trial-form" (ngSubmit)="trialSubmit()" [formGroup]="trialForm">
                        <p>
                            <mat-form-field>
                                <mat-label>Student's first name</mat-label>
                                <input matInput placeholder="Full name" name="student_first_name" formControlName="student_first_name" id="student_first_name" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['student_first_name'].invalid && (trialForm.controls['student_first_name'].dirty || trialForm.controls['student_first_name'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['student_first_name'].errors.required">
                                Please enter student's first name.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Student's last name</mat-label>
                                <input matInput placeholder="Full name" name="student_last_name" formControlName="student_last_name" id="student_last_name" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['student_last_name'].invalid && (trialForm.controls['student_last_name'].dirty || trialForm.controls['student_last_name'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['student_last_name'].errors.required">
                                Please enter student's last name.
                            </div>
                        </div>

                        <p>
                            <mat-form-field>
                                <mat-label>Student's grade</mat-label>
                                <select matNativeControl name="grade" formControlName="grade"  id="grade" required>
                                    <option *ngFor="let grade of gradesList" value="{{grade}}">{{grade}}</option>
                                </select>
                            </mat-form-field>
                        </p>
                        <div *ngIf="trialForm.controls['grade'].invalid && (trialForm.controls['grade'].dirty || trialForm.controls['grade'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['grade'].errors.required">
                            Grade is required.
                            </div>
                        </div>

                        <p>
                            <mat-form-field>
                                <mat-label>Student's reading level (if available)</mat-label>
                                <select matNativeControl name="reading_level" formControlName="reading_level"  id="reading_level">
                                    <option *ngFor="let grade of gradesList" value="{{grade}}">{{grade}}</option>
                                </select>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Desired Username</mat-label>
                                <input matInput placeholder="Username" type="username" name="username_trial" formControlName="username_trial" id="username_trial" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['username_trial'].invalid && (trialForm.controls['username_trial'].dirty || trialForm.controls['username_trial'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['username_trial'].errors.required">
                                Please enter a username.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Desired Password</mat-label>
                                <input matInput placeholder="Password" type="password" name="password_trial" formControlName="password_trial" id="password_trial" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['password_trial'].invalid && (trialForm.controls['password_trial'].dirty || trialForm.controls['password_trial'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['password_trial'].errors.required">
                                Please enter a password.
                            </div>
                        </div>

                        <h3>CHOOSE YOUR PACKAGE</h3>

                        <div class="row choose-package">
                            <div class="col" *ngFor="let license of licenses;let i = index;" (click)="disable_license_by_grade[i] && selectLicense(license.id)">
                                <p ><mat-checkbox [disabled]="!disable_license_by_grade[i]" (click)="disable_license_by_grade[i] && selectLicense(license.id)" class="{{'license'+i}}" [checked]="selectedLicense == license.id"></mat-checkbox></p>
                                <img src="{{license.img}}" alt="{{ license.alt_img }}" />
                                <h4>{{license.title}}</h4>
                                <p><i>${{license.price}} after free trial.</i></p>
                                <p>{{license.long_description}}</p>
                            </div>
                        </div>

                        <h3>CONTACT / BILLING INFO</h3>

                        <p>This information is used to authenticate your account and
                            verify your identity. Your card will NOT be charged until
                            the end of the trial period. You may cancel any time.</p>

                        <p>
                            <mat-form-field>
                                <mat-label>First Name</mat-label>
                                <input matInput placeholder="name" name="name" formControlName="name" id="name" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['name'].invalid && (trialForm.controls['name'].dirty || trialForm.controls['name'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['name'].errors.required">
                            Name is required.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Last Name</mat-label>
                                <input matInput placeholder="last name" name="last_name" formControlName="last_name" id="last_name" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['last_name'].invalid && (trialForm.controls['last_name'].dirty || trialForm.controls['last_name'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['last_name'].errors.required">
                            Last name is required.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="E-mail" name="email" formControlName="email" id="email" email required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['email'].invalid && (trialForm.controls['email'].dirty || trialForm.controls['email'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['email'].errors.required">
                                Please enter your email.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Street Address</mat-label>
                                <input matInput placeholder="Street Address" name="street_address" formControlName="street_address" id="street_address" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['street_address'].invalid && (trialForm.controls['street_address'].dirty || trialForm.controls['street_address'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['street_address'].errors.required">
                            Street Address is required.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>City</mat-label>
                                <input matInput placeholder="City" name="city" formControlName="city" id="city" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="trialForm.controls['city'].invalid && (trialForm.controls['city'].dirty || trialForm.controls['city'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['city'].errors.required">
                            City is required.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>State</mat-label>
                                <select matNativeControl name="state" formControlName="state"  id="state" required>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </mat-form-field>
                        </p>
                        <div *ngIf="trialForm.controls['state'].invalid && (trialForm.controls['state'].dirty || trialForm.controls['state'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['state'].errors.required">
                            State is required.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Zip Code</mat-label>
                                <input matInput placeholder="Zip Code" name="zip_code" formControlName="zip_code"  id="zip_code" required>
                            </mat-form-field>
                        </p>
                        <div *ngIf="trialForm.controls['zip_code'].invalid && (trialForm.controls['zip_code'].dirty || trialForm.controls['zip_code'].touched)" class="alert alert-danger">
                            <div *ngIf="trialForm.controls['zip_code'].errors.required">
                            Zip Code is required.
                            </div>
                            <div *ngIf="trialForm.controls['zip_code'].errors.pattern">
                                Please enter a valid Zip Code.
                            </div>
                        </div>
                    </form>


                    <div class="cell example example4" id="example-4">
                        <div id="example4-paymentRequest">
                        <!--Stripe paymentRequestButton Element inserted here-->
                        </div>
                        <fieldset>
                        <div class="container">
                            <div id="example4-card"></div>
                            <!--<button (click)="createToken()" [disabled]="sendingPayment" class="orange-button checkout-button">CHECKOUT</button>-->
                        </div>
                        </fieldset>
                        <div id="card-errors" class="error" role="alert">
                            <span class="message"></span>
                        </div>

                    </div>

                    <button class="create-trial-button orange-button" [disabled]="sendingPayment" (click)="createToken()">CREATE MY FREE TRIAL ACCOUNT</button>
                    <div style="margin-top: 1em;">
                      <mat-progress-bar *ngIf="sendingPayment" mode="indeterminate"></mat-progress-bar>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
