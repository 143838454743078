import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { APP_FRONTEND_URL, COOKIE_DOMAIN, DASHBOARD_FRONTEND_URL } from '../constants/constants';
import { GlobalsService } from '../services/globals.service';
import { JWTService } from '../services/jwt.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})


export class NavbarComponent implements OnInit {

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  showLogin: boolean = true;
  isStudent: boolean;
  @Input() isFooter: boolean;

  constructor(
    public globals: GlobalsService,
    private cookieService: CookieService,
    private jwtService: JWTService
  ) { }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      if ((<Window>evt.target).innerWidth > 935) {
        this.globals.menuOpen = false;
      }
    });
    const jwt = this.cookieService.get('JWT');
    if (jwt !== '') {
      const payload = this.jwtService.DecodeToken(jwt);
      this.isStudent = payload['category'] === 'student';
      this.showLogin = false;
    } else {
      this.isStudent = true;
      this.showLogin = true;
    }

    
  }

  openCloseMenu() {
    this.globals.menuOpen = !this.globals.menuOpen;
  }

  goToMyMax() {
    window.location.href = APP_FRONTEND_URL;
  }

  goToDashboard() {
    window.location.href = DASHBOARD_FRONTEND_URL;
  }

  async clearCookies() {
    this.cookieService.deleteAll('/', COOKIE_DOMAIN);
  }

  async doLogout() {
    await this.clearCookies();
    window.location.reload();
  }
}
