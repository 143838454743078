import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.scss']
})
export class SoftwareComponent implements OnInit {

  constructor(
    private globals: GlobalsService,
    private meta: Meta,
    private title: Title
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "software";
    }, 5);
    this.title.setTitle("Orton-Gillingham Reading Comprehension Software | MaxScholar");
    this.meta.addTags([
      { name: "title", content: "Orton-Gillingham Reading Comprehension Software | MaxScholar" },
      { name: "keywords", content: "Reading Comprehension Software" },
      { name: "description", content: "MaxScholar's Orton-Gillingham-based Reading Comprehension Software helps students to improve their reading, writing & comprehension abilities. Contact us today." }
    ])
  }

}
