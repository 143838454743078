<div class="book">
    <div class="top" (click)="seeDetail()">
        <img src="{{book.cover_page}}" alt="{{ book.alt_img ? book.alt_img : book.title }}" />
        <h4 [innerHTML]="book.title.replace('-','&#8209;')"></h4>
        <p>{{book.description}}</p>
        <span>Learn more >></span>
    </div>
    <div class="bottom">
        <h3>{{book.title}}</h3>
        <span class="price">{{'$'+book.price}}</span>
        <button class="orange-button" (click)="addToCart()" *ngIf="!cartService.isInCart(unique_id)">ADD TO
            CART</button>
        <button class="red-button" (click)="removeFromCart(unique_id)" *ngIf="cartService.isInCart(unique_id)">REMOVE
            FROM CART</button>
    </div>
</div>

<div *ngIf="showDetail" class="book-detail-obscure"></div>
<div *ngIf="showDetail" class="book-detail">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="close" (click)="closeDetail()"><img src="/assets/img/close.png" alt="close details" /></div>
            </div>
        </div>
        <div class="row">
            <div class="cover-detail col-5">
                <img *ngIf="!seePreview" src="{{book.cover_page}}" alt="{{ book.alt_img ? book.alt_img : book.title }}" />
                <embed *ngIf="seePreview" [attr.src]="book.pdf_file | safe:'resourceUrl'" type="application/pdf"
                    width="100%" height="446px" />
                <div class="see-preview green-button" (click)="doSeePreview()">SEE PREVIEW</div>
            </div>
            <div class="col-7">
                <h3 class="subtitle">{{book.category}}</h3>
                <h1>{{book.title}}</h1>
                <p>{{book.description}}</p>

                <div class="price-tag">
                    <span class="price">${{book.price}}</span>
                    <span *ngIf="category == 'WORKBOOK'" class="excluding">* excluding shipping</span>
                </div>

                <button class="add-to-cart orange-button" (click)="addToCart()"
                    *ngIf="!cartService.isInCart(unique_id)">ADD TO CART</button>
                <button class="add-to-cart red-button" (click)="removeFromCart(id)"
                    *ngIf="cartService.isInCart(unique_id)">REMOVE FROM CART</button>
            </div>
        </div>
    </div>

</div>