<section class="main-section first-section" id="privacy-policy">
    <div class="content">

        <h1>Terms of Use</h1>
        <p>These TERMS OF USE have been incorporated into and made a part of the certain Software License Agreement by
            and between MAXScholar LLC and Customer.</p>

        <h3>1. License Grant</h3>
        <p>Subject to the terms and conditions of this Agreement, MaxScholar grants to Customer, and Customer hereby
            accepts, a personal, non-transferable, non-sub-licenseable, revocable, non-exclusive. Limited right and
            license to allow Administrative Users and Teacher/Student Users to access and use the specific MaxScholar
            software identified in the invoice, solely during the period beginning upon the start of the License Term
            and ending upon the expiration of the term, and solely for the purposes expressly permitted in the
            Agreement.
        </p>

        <h3>2. Administrative Users</h3>
        <p>MaxScholar will issue the Customer administrator(s) who will supervise the use by teachers and
            paraprofessionals a specific username and password required to access the administrative part of the
            software. This administrator(s) will then be permitted to create usernames and passwords for additional
            customer administrators who will then be permitted to create usernames and passwords for additional customer
            administrators. Alternatively, the process of creating the usernames and passwords may be performed by the
            MaxScholar staff and delivered via email to the administrator who will distribute them to the appropriate
            administrator or teacher for their use.
        </p>

        <h3>3. Student / Teacher Users</h3>
        <p>Administrative users may create usernames and passwords for permitted Teacher/Student Users of the software.
            Alternatively, the provision of the Teacher/Student usernames and passwords may be provided by the
            MaxScholar staff and distributed to the lead teacher or administrator of the school or directly to the
            teachers. Usernames and passwords provided shall not exceed the number of teacher/student users provided in
            the contract or invoice.
        </p>

        <h3>4. Scope of License</h3>
        <p>A. Permitted Users. Customer and Customer's Administrative Users shall access and use the Software solely for
            the Customer's internal operations, solely for the benefit of the Customer's permitted Teacher/Student Users
            and solely for the purpose of administering courses offered by the Customer using the Software to permitted
            Teacher/Student Users. By way of example: if Customer is a single school, Customer shall use the Software
            solely for the benefit of students enrolled at that school and such students shall be the sole
            Teacher/Student Users of the Software; if Customer is a school district,Customer shall use the Software
            solely
            for the benefit of the students enrolled in the school district, and such students shall be the sole
            Teacher/Student
            Users of the Software; if the Customer is a private (for profit) organization, Customer shall use the
            Software
            solely for the benefit of the Teacher/Student Users using MaxScholar, and such clients shall be the sole
            Teacher/Student Users of the Software. Failure to adhere to the Agreement may result, in addition to all
            other
            rights and remedies available to MaxScholar, in additional usage fees.
            <br>B. Permitted Access. Customer shall not, and Customer shall ensure that its Administrative Users and
            Users do not, divulge, sublicense or otherwise transfer to any third party any user names or passwords for
            the software. Customers shall be solely responsible for the actions and inactions of its Administrative
            Users and End Users, and for maintaining the integrity, security and confidentiality of the usernames and
            passwords for the Software. Customers shall advise MaxScholar immediately if any of the usernames or
            passwords for the Software have been compromised.
        </p>

        <h3>5. Restrictions </h3>
        <p>Except as may be otherwise expressly permitted in another agreement with MaxScholar to which Customer is a
            party, Customer may not: (i) modify or create any derivative works of the Software, including customization,
            translation or localization; <br>
            (ii) decompile, disassemble, reverse engineer, or otherwise attempt to derive
            the source code for the Software; <br>
            (iii) redistribute, encumber, sell, rent, lease, sublicense, or otherwise
            transfer or make the Software available to any third party; <br>
            (iv) remove or alter any trademark, logo,
            copyright or other proprietary notices, legends, symbols or labels in the Software; <br>
            (v) publish any results
            of benchmark tests run on the Software to a third party without MaxScholar's prior written consent; <br>
            (vi) create software that is substantially similar to the Software; or <br>
            (vii) during the Term and for a period of three (3) years thereafter, create any software that competes with
            the Software.
        </p>

        <h3>6. Suspension of Access</h3>
        <p>In addition to MaxScholar's right to terminate this Agreement, MaxScholar may terminate Customer's access to
            the Software upon notice to Customer if Customer fails to pay any fees due to MaxScholar on a timely basis
            or breaches any material provision of this Agreement.
        </p>

        <h3>7. Term</h3>
        <p>The term of this Agreement will begin on the Effective Date identified on the contract or invoice and, unless
            earlier terminated, terminate upon the expiration of the License Term identified on the Invoice.
        </p>

        <h3>8. Termination</h3>
        <p>a. Termination For Breach. MaxScholar may terminate this Agreement if the Customer violates any material
            provision of this Agreement and fails to cure that violation within ten (10) days after its receipt of
            written notice from MaxScholar identifying the violation. b. Effect of Termination. Upon expiration or
            earlier termination of this Agreement for any reason, all rights granted to the Customer under this
            Agreement shall immediately terminate, Customer (including all Administrative Users and Users) shall have no
            further right to access or use the Software and Customer must promptly cease all use of the Software. As
            soon as commercially reasonable (but in no event more than thirty (30) days following termination), Customer
            shall return to MaxScholar or destroy, at Customer's expense, all documentation relating to or derived from
            the Software. c. Survival. Any provisions of the Agreement which by their nature or express terms should
            survive termination or expiration shall survive termination or expiration including, without limitation,
            Sections 5, 8, 10, 11, 12 and Sections 14 through 21 of these Terms.
        </p>

        <h3>9. Technical Assistance</h3>
        <p>During the Term, MaxScholar personnel shall be available from 9:00 a.m. to 5:00 p.m. ET to provide the
            Customer a reasonable amount of technical assistance regarding use of the Software by telephone, email or
            other web-based communication tools.
        </p>

        <h3>10. License Fees </h3>
        <p>In consideration for the rights and licenses granted to Customer herein, Customer shall pay MaxScholar the
            fees set forth in the invoice. All fees are in U.S. Dollars and are non-refundable. Unless otherwise
            provided in the invoice, all fees shall be due and payable within thirty (30) days of the Customer's receipt
            of an invoice from MaxScholar.
        </p>

        <h3>11. Additional Fees</h3>
        <p>Customers acknowledge and agree that there may be features or additional services (such as Professional
            Development, MaxScholar Orton-Gillinham Level 1 Certification, and MaxScholar-developed instructional
            materials) made available by MaxScholar for additional fees. If the Customer chooses to utilize such
            services or products, Customer agrees to be bound by any additional terms and conditions governing the use
            of such features or services and to pay all additional fees related to such features or services. These fees
            may relate to technical support contracts, user orientation training, information back-ups, additional
            program access or other program enhancements.
        </p>

        <h3>12. Proprietary Rights</h3>
        <p>The Software is the sole property of MaxScholar and its licensors. All right, title and interest in and to
            the Software and all intellectual property rights therein shall remain exclusively with MaxScholar and its
            licensors. Customers acknowledge such right, title and interest of MaxScholar and its licensors and will not
            take any action to jeopardize, limit or interfere in any manner with MaxScholar and its licensors' ownership
            of or rights with respect to the Software. Customers further acknowledge the Software is protected by
            copyright and other intellectual property laws and by international treaties.
        </p>

        <h3>13. Scheduled Maintenance</h3>
        <p>MaxScholar will perform routine maintenance on the servers used to run the Software. Such routine maintenance
            often requires taking the servers and the Software off-line. MaxScholar estimates that it will require at
            least one hour of server and Software unavailability per month for such routine maintenance. Server and
            Software unavailability associated with such routine maintenance shall not be included in any server or
            Software uptime calculations. MaxScholar will use commercially reasonable efforts to perform such routine
            maintenance during off-peak hours.
        </p>

        <h3>14. Disclaimer of Warranty</h3>
        <p>Except as expressly provided in section 13 above, the software and all data, and content available through
            the software are provided “as is” and on an “as available” basis, without warranty or condition of any kind,
            either express or implied, and MaxScholar expressly disclaims all warranties of any kind, including without
            limitation warranties that the software is free of defects, virus free, able to operate on an uninterrupted
            basis, merchantable, fit for a particular purpose or non-infringing. The entire risk as to the results
            achieved with the software is borne by the customer. In addition, the security mechanisms implemented by the
            software have inherent limitations, and customers must determine that the software sufficiently meets its
            requirements. This disclaimer of warranty constitutes an essential part of this agreement. No use of the
            software is authorized hereunder except under this disclaimer.
        </p>

        <h3>15. Exclusion of Consequential Damages</h3>
        <p>Neither MaxScholar, nor its employees, distributors, resellers, directors or agents shall be liable to
            customer or anyone else for any indirect, incidental, consequential, special, punitive or exemplary damages,
            including but not limited to lost profits and lost data, in any way arising out of or relating to this
            agreement, even in the event MaxScholar, its employees, distributors, resellers, directors or agents have
            been advised as to the possibility of such damages. Because some states or jurisdictions do not allow the
            exclusion or the limitation of liability for consequential or incidental damages, in such states or
            jurisdictions, liability shall be limited to the extent permitted by law.
        </p>

        <h3>16. Limitation of Liability</h3>
        <p>The entire collective liability of MaxScholar, its employees, distributors, resellers, directors and agents
            under any provision of this agreement shall not exceed in the aggregate the sum of the fees customer
            actually paid to MaxScholar pursuant to this agreement for access to and use of the software in the prior
            twelve (12) month period. Because some states or jurisdictions do not allow the exclusion or the limitation
            of liability for consequential or incidental damages, in such states or jurisdictions, MaxScholar's
            liability shall be limited to the extent permitted by law.
        </p>

        <h3>17. User Data</h3>
        <p>The Software will automatically send information from Administrative Users and Teacher/Student Users back to
            the servers operating the Software. MaxScholar, and its authorized resellers, sales representatives and
            agents, may use the non-user identifiable portions of this data, for purposes including improvements to
            MaxScholar programs, educational research and promotional purposes. The information may include, for
            example, data relating to the performance of Users within the various programs in the Software, conflicts or
            errors encountered during the download and install process, and conflicts created during the download and
            install process based on the hardware configuration encountered. Customers have sole responsibility for
            maintaining student records and monitoring student, teacher, class and school (in the case of district
            sales) additions, edits and deletes.
        </p>

        <h3>18. Customer Representations</h3>
        <p>Customer represents and warrants to MaxScholar that Customer has the adequate legal capacity to enter into
            this Agreement and to use and authorize MaxScholar to use (as contemplated by this Agreement) all data
            uploaded to the Software by Customer, Administrative Users and Teacher/Student Users. Customer further
            represents and warrants that Customer will use the Software only for lawful purposes and in accordance with
            this Agreement, and that Customer will not use the Software to violate any law, regulation or ordinance or
            any right of MaxScholar including, without limitation, any right of privacy, publicity, copyright,
            trademark, or other intellectual property right.
        </p>

        <h3>19. Injunctive Relief</h3>
        <p>Customer acknowledges that the Software contains MaxScholar proprietary and confidential information, and
            that disclosure of such information to any third party or misuse of the Software is both <br>
            (i) expressly prohibited and <br>
            (ii) will give rise to irreparable injury to MaxScholar, inadequately compensable in damages.<br>
            Accordingly, MaxScholar may seek and obtain injunctive relief against the breach or threatened breach of the
            foregoing undertakings, in addition to any other legal remedies that may be available.
        </p>

        <h3>20. Miscellaneous</h3>
        <p>
            (A) This Agreement shall be governed by the laws of the State of Florida, U.S.A., excluding its conflict of
            law provisions. <br>
            (B) Customer expressly agrees that the exclusive jurisdiction and venue for any claim or
            dispute arising from the use of the Software or this Agreement resides in the federal and state courts
            located in the State of Florida. <br>
            (C) This Agreement shall not be governed by the United Nations Convention on Contracts for the International
            Sale of Goods. <br>
            (D) If any part of this Agreement is held invalid or unenforceable, that part shall be construed to reflect
            the Parties' original intent, and the remaining portions remain in full force and effect. <br>
            (E) The controlling language of this Agreement is English. <br>
            (F) A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one
            instance, shall not waive such term or condition or any subsequent breach thereof. <br>
            (G) Customer may not assign or otherwise transfer by operation of law or otherwise this Agreement or any
            rights or obligations
            herein except in the case of a merger or the sale of all or substantially all of Customer's assets to
            another entity, and any attempted assignment or transfer in violation of this Section 20(g) shall be null
            and void. <br>
            (H) This Agreement shall be binding upon and shall inure to the benefit of the Parties, their successors and
            permitted assigns. <br>
            (I) If any dispute arises under this Agreement, the substantially
            prevailing Party shall be reimbursed by the other Party for any and all reasonable legal fees and costs
            associated therewith. <br>
            (J) MaxScholar may use Customer's name in any customer reference list or in any press release issued by
            MaxScholar regarding the licensing of the Software and/or provide Customer's name and the names of the
            Software licensed by Customer to third parties.
        </p>

        <h3>21. Entire Agreement</h3>
        <p>This Agreement constitutes the entire agreement and understanding between the Parties with respect to the
            subject matter of this Agreement and supersedes all prior proposals, discussions, other oral communications,
            written communications and agreements with respect to such subject matter. This Agreement may be modified
            only by mutual written agreement of the Parties.
        </p>
    </div>
</section>