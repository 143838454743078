<section class="main-section first-section left-aligned-header" id="section-header">
    <div class="content">
        <h1>LEARNING DISABILITIES</h1>
        <p>Learning Disabilities affect approximately 1 in 5 people in the United States.
            Read below what the MaxScholar Team is doing to help.</p>
        <button routerLink="/contact" class="orange-button">CONTACT US</button> <span style="padding-left:15px;">FREE
            assessment!</span>
    </div>
</section>

<div class="bgr-shapes">


    <section id="ld-content">
        <div class="container">
            <h3 class="subtitle">About disabilities</h3>
            <h1>Best Online Reading Programs For Dyslexia</h1>
            <p>We would be more than happy to give you a personalized demo.</p>

            <img src="/assets/img/learning-disabilities-1.jpg" alt="Best Reading Programs for Dyslexia" />

            <h3 style="margin-top:2em;">Dyslexia</h3>

            <div class="content learning-list">
                <div class="row">
                    <div class="col-md">
                        Dyslexia is a reading disorder which can be characterized by poor word reading, poor reading
                        comprehension, poor spelling, poor auditory processing of language, poor writing or a
                        combination of these issues.
                    </div>
                    <div class="col-md">
                        In most cases, intellectual ability is in no way affected by dyslexia. Early intervention
                        programs like MaxScholar can effectively correct these problems before lack of reading knowledge
                        hinders students in the classroom.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        In most cases, intellectual ability is in no way affected by dyslexia. Early intervention
                        programs like MaxScholar can effectively correct these problems before lack of reading knowledge
                        hinders students in the classroom.
                    </div>
                    <div class="col-md center-flex">
                        <button class="orange-inverse-button" routerLink="/home-licenses">HOME LICENSE</button> <span
                            class="maxscholar-text"><img src="/assets/img/maxscholar-image-logo.svg"
                                alt="MaxScholar Image Logo" /> MAXSCHOLAR Software license for home use.</span>
                    </div>
                </div>
            </div>

            <div class="content learning-list">
                <div class="row">
                    <div class="col-md learning-content">
                        <div class="content-box">
                            <p>MaxScholar is working to ensure that every student has a chance to learn in a way that
                                works for them.</p>
                        </div>
                    </div>
                    <div class="col-md">
                        <img src="/assets/img/learning-disabilities-2.jpg" alt="Best Online Programs for Dyslexia" />
                    </div>
                </div>
            </div>

            <h3 style="margin-top:2em;">Auditory Processing Disorders And Reading</h3>

            <div class="content learning-list">
                <div class="row">
                    <div class="col-md">
                        Through highlighting practice in MaxReading, students must visually determine the important
                        details in a passage. Listening to music has also been proven to develop auditory processing
                        skills. MaxMusic uses music and games to work on several areas of reading comprehension and
                        summarization.
                    </div>
                    <div class="col-md">
                        Summarizing, retelling and putting into their own words what they have read helps treat or
                        remediate auditory processing disorders. Children can retell the text from the multisensory oral
                        presentation (using our Read Aloud feature), or through reading and retelling the passage.
                    </div>
                </div>
                <div class="row" style="margin-top:2em;">
                    <div class="col">
                        <button class="orange-inverse-button" routerLink="/home-licenses">HOME LICENSE</button> <span
                            class="maxscholar-text"><img src="/assets/img/maxscholar-image-logo.svg"
                                alt="MaxScholar Image Logo" /> MAXSCHOLAR Software license for home use.</span>
                    </div>
                </div>
            </div>

            <img style="margin-top:2em;" src="/assets/img/learning-disabilities-3.jpg"
                alt="Best Online Reading Programs for Dyslexia" />

            <h3 style="margin-top:2em;">Attention Deficit Hyperactivity Disorder</h3>

            <div class="content learning-list">
                <div class="row">
                    <div class="col-md">
                        Children that show impulsivity or inattention considered inappropriate for their age level could
                        be suffering from Attention Deficit Hyperactivity Disorder (ADHD), also commonly referred to as
                        ADD (Attention Deficit Disorder).
                    </div>
                    <div class="col-md">
                        Different tools must be implemented when trying to teach younger readers with a short attention
                        span. Mixing different multisensory games and activities in a way that keeps the learner engaged
                        for an extended period of time is crucial for comprehension and retention.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        MaxScholar’s full writing & reading software gives your student both in-depth exercises needed
                        to get past learning difficulties and a fun system that holds their attention.
                    </div>
                    <div class="col-md center-flex">
                        <button class="orange-inverse-button" routerLink="/home-licenses">HOME LICENSE</button> <span
                            class="maxscholar-text"><img src="/assets/img/maxscholar-image-logo.svg"
                                alt="MaxScholar Image Logo" /> MAXSCHOLAR Software license for home use.</span>
                    </div>
                </div>
            </div>

            <h3 style="margin-top:2em;">Autism and Asperger Syndrome</h3>

            <div class="content learning-list">
                <div class="row">
                    <div class="col-md">
                        Learning can be a challenge for people with Autism or Asperger Syndrome, but with the right
                        attention and instruction, the brain can be rewired to learn in an effective way.
                    </div>
                    <div class="col-md">
                        Language and processing delays range from mild to severe, and are the center of a diagnosis.
                        MaxScholar is the perfect intermediary for integrating phonics and reading skills in order to
                        help enforce social interactions.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        The captivating content helps the children improve their focus, which is beneficial for
                        developing other skills later in life.
                    </div>
                    <div class="col-md center-flex">
                        <button class="orange-inverse-button" routerLink="/home-licenses">HOME LICENSE</button> <span
                            class="maxscholar-text"><img src="/assets/img/maxscholar-image-logo.svg"
                                alt="MaxScholar Image Logo" /> MAXSCHOLAR Software license for home use.</span>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>