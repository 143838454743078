import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  quotes: Array<string> = [
    '"The program is so rich with content and potential benefit to students that really any student would find benefit for using the program!" - Kelly - Homeschooling mom',
    '"This program would be a great fit for any student who is having trouble learning to read. It is especially effective for students with dyslexia or other severe reading problems." - Maggie - Teacher and Parent',
    '"I greatly appreciate the multisensory approach that this program takes because, we all know how different every child can be. It is nice to find something that can work for your youngest and your oldest, in whatever season of learning they are in." - Allison - Teacher'
  ]
  whichQuote: number = 0;

  constructor() { }

  ngOnInit(): void {
    
    this.whichQuote = Math.floor(Math.random() * this.quotes.length);
  }

}
