import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { DataService } from '../services/data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-check-user',
  templateUrl: './check-user.component.html',
  styleUrls: ['./check-user.component.scss']
})
export class CheckUserComponent implements OnInit {


  form: FormGroup;
  kind:string;

  sending: boolean = false;

  error: boolean = false;

  selected: Date | null;

  startDate = new Date();

  minDate: Date;

  weekendFilter: any = (d: Moment | null): boolean => {
    if (d) {
      const day = d.day();
      return day !== 0 && day !== 6;
    } else {
      return false;
    }
  }



  constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<CheckUserComponent>,
      private dataService: DataService,
      @Inject(MAT_DIALOG_DATA) public data: any
      ) {

        this.kind = data.kind;

        const today = new Date().getTime();
        var day = 60 * 60 * 24 * 1000;

        this.minDate = new Date(today + day*7);
  }

  ngOnInit() {
      if (this.kind == "OW") {
        this.form = this.fb.group({
          username: [null, []],
          password: [null, []],
        });
      } else if (this.kind == "IST") {
        this.form = this.fb.group({
            code: [null, []],
            date: [null],
            timezone: [null],
            time: [null]
        });
      }


      let optionalFields = ['code', 'day','timezone','time'];

      if (this.kind == "IST") {
        optionalFields.forEach((field) => {
          this.form.controls[field].setValidators([Validators.required]);
          this.form.controls[field].updateValueAndValidity();
        });
  
      } else {
        optionalFields.forEach((field) => {
          this.form.controls[field].clearValidators();
          this.form.controls[field].updateValueAndValidity();
        });
      }
  }

  check() {

    if (this.form.valid) {
      this.sending = true;
  
      var formData: any = new FormData();
      formData.append("kind", this.kind);

      if (this.kind == "OW") {
        formData.append("username", this.form.get('username').value);
        formData.append("password", this.form.get('password').value);
      } else if (this.kind == "IST") {
        formData.append("code", this.form.get('code').value);
        let datetime_string = moment(this.form.get('date').value, "MM-DD-YYYY").format('YYYY-MM-DD')+"T"+this.form.get('time').value;
        formData.append("date", datetime_string);
        formData.append("tz", this.form.get('timezone').value);
      }
      
      this.dataService.checkTrainingUser(formData).subscribe((response) => {
        this.dialogRef.close("success");
      }, (error) => {
        console.log(error);
        this.sending = false;
        this.error = true;
      })
    }
  }

  close() {
      this.dialogRef.close("no_success");
  }

  contact() {
    this.dialogRef.close("contact");
  }


}
