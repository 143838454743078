<section id="main">
  <navbar [isFooter]='false' id="main-navbar"></navbar>

    <router-outlet ></router-outlet>
</section>

<section id="footer-navigation">
  <navbar [isFooter]='true'></navbar>
</section>

<cart-widget></cart-widget>