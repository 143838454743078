import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../services/cart.service';
import { Product } from '../classes/product';
import { Book } from '../classes/book';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  @Input() book: Book;
  unique_id: string;

  showDetail: boolean = false;
  seePreview: boolean = false;

  constructor(
    public cartService: CartService
  ) { }

  ngOnInit(): void {
    this.unique_id = "book_"+this.book.id;
  }

  seeDetail() {
    this.showDetail = true;
  }
  closeDetail() {
    this.showDetail = false;
  }


  addToCart() {
    let product = new Product();
    product.title = this.book.title;
    product.price = this.book.price;
    product.id = this.book.id;
    product.unique_id = this.unique_id
    product.category = this.book.category;
    product.description  = this.book.description;
    product.cover = this.book.cover_page;
    product.quantity = 1;

    this.cartService.addToCart(product);
  }

  removeFromCart() {
    this.cartService.removeFromCart(this.unique_id);
  }

  doSeePreview() {
    this.seePreview = true;
  }

}
