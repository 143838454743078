import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './checkout/checkout.component';
import { ADMIN_URL } from './constants/constants';
import { ContactComponent } from './contact/contact.component';
import { DoeFundingComponent } from './doe-funding/doe-funding.component';
import { RedirectGuard } from './guard/redirect-guard.guard';
import { ClassLoginComponent } from './class-login/class-login.component';
import { ClassLoginGuard } from './guards/class-login.guard';
import { HomeLicensesComponent } from './home-licenses/home-licenses.component';
import { HomeComponent } from './home/home.component';
import { LearningDisabilitiesComponent } from './learning-disabilities/learning-disabilities.component';
import { LoginComponent } from './login/login.component';
import { MethodsComponent } from './methods/methods.component';
import { PasswordResetSubmissionComponent } from './password-reset-submission/password-reset-submission.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SchoolLicensesComponent } from './school-licenses/school-licenses.component';
import { SoftwareComponent } from './software/software.component';
import { SupportComponent } from './support/support.component';
import { TrainingComponent } from './training/training.component';
import { TutoringComponent } from './tutoring/tutoring.component';
import { WorkbooksEbooksComponent } from './workbooks-ebooks/workbooks-ebooks.component';
import { PurchaseSuccessComponent } from './purchase-success/purchase-success.component';
import { PurchaseSuccessGuard } from './guards/purchase-success.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'software', component: SoftwareComponent},
  { path: 'workbooks-ebooks', component: WorkbooksEbooksComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'checkout', component: CheckoutComponent},
  { path: 'home-licenses', component: HomeLicensesComponent},
  { path: 'school-licenses', component: SchoolLicensesComponent},
  { path: 'faq', component: SupportComponent},
  { path: 'og-training', component: TrainingComponent},
  { path: 'tutoring', component: TutoringComponent},
  { path: 'login',component: LoginComponent},
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'password-reset-submission', component: PasswordResetSubmissionComponent },
  { path: 'learning-disabilities', component: LearningDisabilitiesComponent },
  { path: 'methods', component: MethodsComponent },
  { path: 'doe-funding', component: DoeFundingComponent },
  { path: 'admin', canActivate: [RedirectGuard], component: HomeComponent, data: { externalUrl: ADMIN_URL } },
  { path: 'class-login/:clase_uuid',component: ClassLoginComponent, canActivate: [ClassLoginGuard] },

  { path: 'thank-you/:type', component: PurchaseSuccessComponent, canActivate: [PurchaseSuccessGuard]},
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
