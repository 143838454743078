<section class="main-section first-section" id="checkout-header">
</section>
<section id="checkout-content" *ngIf="cartService.items.length > 0" [hidden]="globals.checkoutSuccessful">
    <div class="content">
        <h3 class="subtitle">Shopping</h3>
        <h1>Cart</h1>

        <div class="cart-top container">
            <div class="row">
                <div class="checkout-items-list col-8">
                    <!-- THIS COULD BE A COMPONENT BUT NOT NECESSARY REALLY -->
                    <checkout-item *ngFor="let item of cartService.items" [item]="item"></checkout-item>
                </div>

                <div id="cart-summary" class="col-4">
                    <h2>
                        <svg _ngcontent-gmd-c135="" width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-gmd-c135="" d="M6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.59L4.25 12.04C4.09 12.32 4 12.65 4 13C4 14.1 4.9 15 6 15H18V13H6.42C6.28 13 6.17 12.89 6.17 12.75L6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C19.96 3.34 20 3.17 20 3C20 2.45 19.55 2 19 2H4.21L3.27 0H0ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z" class="fill-color"></path></svg>
                        Your cart
                    </h2>
                    <p>Items ({{cartService.items.length}})</p>
                    <div class="container">
                        <div class="row" *ngFor="let item of cartService.items">
                            <div class="col-8">
                                {{item.title}}
                            </div>
                            <div class="col-4">
                                ${{item.price}} x {{item.quantity}}
                            </div>
                        </div>
                        <div class="row total-price">
                            <div class="col-9">
                                <strong>TOTAL:</strong>
                            </div>
                            <div class="col-3">
                                ${{cartService.totalPrice}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <payment-form [total]="cartService.totalPrice"></payment-form>
</section>
<section id="checkout-content" class="no-items" *ngIf="cartService.items.length == 0 && !globals.checkoutSuccessful">
    <div class="content">
        <p>There are no items in your cart.</p><br><br>
        <p><button routerLink="/home-licenses" class="orange-button">ADD LICENSES</button></p>
        <p><button routerLink="/workbooks-ebooks" class="orange-button">ADD WORKBOOKS</button></p>
        <p><button routerLink="/og-training" class="orange-button">ADD TRAINING</button></p>
    </div>
</section>
<section id="checkout-content" class="no-items" *ngIf="globals.checkoutSuccessful">
    <div class="content">
        <h1>Checkout successful!</h1>
        <p>Please check your e-mail for more information.</p><br>
        <p>You can click <a target="_blank" [href]="globals.receipt_url">here</a> to see the receipt online.</p>
    </div>
</section>
