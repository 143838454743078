<div class="item container">
    <div class="row">
        <div class="col-3 product-image"><img src="{{item.cover}}" alt="checkout item cover" /></div>
        <div class="col-7">
            <h3>{{item.title}}</h3>
            <p class="description">{{item.description}}</p>
        </div>
        <div class="col-2">
            <div class="price">${{item.price}}</div>
        </div>
    </div>
    <div class="row">
        <div class="col adjust-quantity">
            <div>
                <button style="font-size: 1.6em;" mat-stroked-button color="accent" (click)="minus()">-</button>
            </div>
            <div class="quantity-inline">
                {{productQuantity}}
            </div>
            <div>
                <button mat-stroked-button color="accent" (click)="plus()">+</button>
            </div>
        </div>
    </div>
</div>