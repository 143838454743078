<section class="main-section first-section left-aligned-header" id="section-header">
    <div class="content">
        <h1>Orton-Gillingham workbooks with federal funding</h1>
        <p>All our books follow O-G lesson plans and include fluency, visual and kinesthetic drills, sight word,
            controlled readers, and more!</p>
    </div>
</section>

<div class="bgr-shapes">
    <section id="doe-content">
        <div class="container">
            <h3 class="subtitle">New York</h3>
            <h1>Department of education</h1>
            <p>We are glad to announce our series of Orton-Gillingham workbooks is now available for through NYSTL
                funding!</p>

            <img style="margin-bottom:2em" src="/assets/img/nyc-doe.jpg" alt="NYC Department of Education" />

            <h3 class="subtitle">Step by step</h3>
            <h1>How to order workbooks</h1>
            <p>Follow this steps to get our Ortin-Gillingham workbooks at <a href="https://shopdoe.com">shopdoe.com</a>
            </p>

            <div class="container doe-description">
                <div class="row">
                    <div class="col-md center-flex">
                        <h3><span class="num">1.</span> Log into account at shopdoe.com</h3>
                        <p>If you don’t have an account, make sure to "Sign Up" and activate it. Then click "Take me
                            shopping".</p>
                    </div>
                    <div class="col-md">
                        <img src="/assets/img/doe-tutorial-1.jpg" alt="NYC Department of Education tutorial 1" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md center-flex">
                        <h3><span class="num">2.</span> Use the search</h3>
                        <p>Once you are in the store, use the search box to look for “MaxScholar” products. Select a
                            title, or click “Search” to see all our products.</p>
                    </div>
                    <div class="col-md">
                        <img src="/assets/img/doe-tutorial-2.jpg" alt="NYC Department of Education tutorial 2" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md center-flex">
                        <h3><span class="num">3.</span> Collection</h3>
                        <p>You will be able to see our full collection of workbooks and purchase them directly through
                            the website with your NYSTL funding! Our workbooks follow research-based methods that can be
                            found in our software, as well as other strategies that are taught through the workbooks
                            themselves!</p>
                    </div>
                    <div class="col-md">
                        <img src="/assets/img/doe-tutorial-3.jpg" alt="NYC Department of Education tutorial 3" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md center-flex">
                        <h3><span class="num">4.</span> Description</h3>
                        <p>If you want to get a description of what each workbook contains, feel free to go to the
                            workbook store in our website. You will be able to download a sample before you decide to do
                            the purchase via the DOE website!</p>
                    </div>
                    <div class="col-md">
                        <img src="/assets/img/doe-tutorial-4.jpg" alt="NYC Department of Education tutorial 4" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md center-flex">
                        <h3><span class="num">5.</span> Checkout</h3>
                        <p>Select the workbooks and amounts you want to add them to your cart. Then click “Checkout”.
                        </p>
                    </div>
                    <div class="col-md">
                        <img src="/assets/img/doe-tutorial-5.jpg" alt="NYC Department of Education tutorial 5" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md center-flex">
                        <h3><span class="num">6.</span> Finalize order request</h3>
                        <p>Enter and review your details, then click on “Finalize Order Request”. The order should then
                            be on its way to your school!</p>
                    </div>
                    <div class="col-md">
                        <img src="/assets/img/doe-tutorial-6.jpg" alt="NYC Department of Education tutorial 6" />
                    </div>
                </div>

                <h3 class="contact-h3">Don’t hesitate to contact us with any questions <button routerLink="/contact"
                        class="orange-button">CONTACT US</button></h3>
            </div>
        </div>
    </section>
</div>