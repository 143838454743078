import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-school-licenses',
  templateUrl: './school-licenses.component.html',
  styleUrls: ['./school-licenses.component.scss']
})
export class SchoolLicensesComponent implements OnInit {

  constructor(
    private globals: GlobalsService,
    private meta: Meta,
    private title: Title
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "software";
    }, 5);
    this.title.setTitle("Orton-Gillingham Reading Programs for Schools | MaxScholar");
    this.meta.addTags([
      { name: "title", content: "Orton-Gillingham Reading Programs for Schools | MaxScholar" },
      { name: "keywords", content: "Orton-Gillingham Reading Programs, OG Reading Program" },
      { name: "description", content: "MaxScholar is the Best Orton-Gillingham Reading Programs for Schools offering ideal blended learning solutions at affordable options. Contact us today." }
    ])
  }

}
