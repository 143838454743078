import { Component, OnInit } from '@angular/core';
import { CartService } from '../services/cart.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  
  constructor(
    public cartService: CartService,
    public globals: GlobalsService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "cart";
    }, 5);
  }

  

}
