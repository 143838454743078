<div class="training-item content {{trainingClass}}">
    <div class="row">
        <div class="col-3">
            <h5>{{training.title}}</h5>
            <div class="open-details" (click)="openDetails()"><span [innerHTML]="showDetail? '- Hide': '+ See'"></span> full description</div>
        </div>
        <div class="col-2" *ngIf="training.kind != 'IST'">
            <div class="date" [innerHTML]="insertBreakAtDash(training.date)"></div>
        </div>
        <!-- OG -->
        <div class="col-2" *ngIf="training.kind == 'OG'">
            <span >{{training.location}}</span>
        </div>
        <div class="col-2" *ngIf="training.kind == 'OG'">
            <span>${{training.price}}</span>
        </div>
        <div class="col-3" *ngIf="training.kind == 'OG'" style="text-align: center;">
            <button class="orange-button" (click)="chooseTraining()">BUY NOW</button>
        </div>

        <!-- OW -->
        <!-- SPACING PLACEHOLDER -->
        <div class="col-2" *ngIf="training.kind == 'OW'">
            <span >online ZOOM</span>
        </div>
        <div class="col-2" *ngIf="training.kind == 'OW' && !registered">
        </div>
        <div class="col-3" *ngIf="training.kind == 'OW' && !registered" style="text-align: center;">
            <button class="orange-button" (click)="chooseTraining()">BOOK DATE</button>
        </div>
        <div class="col-5" *ngIf="training.kind == 'OW' && registered">
            <p class="register">Thank you! You can now <a class="orange-button" href="{{training.location}}" target="_blank">CLICK HERE</a> to register.</p>
        </div>

        <!-- IST -->
        <div class="col-7" *ngIf="training.kind == 'IST' && registered">
            <p class="register">Thank you! Your request has been sent. Please allow up to 48 hours to receive a confirmation e-mail.</p>
        </div>
        <div class="col-6" *ngIf="training.kind == 'IST' && !registered">
        </div>

        <div class="col-3" *ngIf="training.kind == 'IST' && !registered" style="text-align: center;">
            <button class="orange-button" (click)="chooseTraining()">BOOK DATE</button>
        </div>


        
    </div>
    <div class="row training-detail" *ngIf="showDetail">
        <div class="col">
            <p>{{training.description}}</p>
        </div>
    </div>
</div>