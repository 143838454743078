import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-password-reset-submission',
  templateUrl: './password-reset-submission.component.html',
  styleUrls: ['../password-reset/password-reset.component.scss']
})
export class PasswordResetSubmissionComponent implements OnInit {
  resetForm: FormGroup;

  resetComplete: boolean = false;
  sendingReset: boolean = false;
  jwttoken: string;

  constructor(
    private route: ActivatedRoute,
    private globals: GlobalsService,
    private dataService: DataService,
    private fb: FormBuilder
  ) {
    this.resetForm = this.fb.group({
      password: [null],
      rePassword: [null]
    },{validator: this.passwordConfirming});

  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.jwttoken = params.jwttoken;
      }
    );

    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "login";
    }, 5);
  }

  onSubmit() {
    this.sendingReset = true;

    var formData: any = new FormData();
    formData.append("password", this.resetForm.get('password')!.value);
    formData.append("password2", this.resetForm.get('rePassword')!.value);
    formData.append("token", this.jwttoken);

    this.dataService.setPassword(formData).subscribe(()=> {
      this.resetComplete = true;
    }, (error) =>{
      alert("Sorry, an error has ocurred.")
      this.sendingReset = false;
    })
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('rePassword').value) {
        return {invalid: true};
    }
    return null
  }
}

