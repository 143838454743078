import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GlobalsService } from '../services/globals.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseSuccessGuard implements CanActivate {
  constructor(
    private globals: GlobalsService,
    private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      if (!this.globals.checkoutSuccessful) {
        this.router.navigate(['/']);
        return of(false);
      } else {
        return of(true);
      }
  }

  
}
