<section class="main-section first-section" id="workbooks">
    <div class="content">
        <h1>Workbooks & Materials</h1>
        <p>Explore all the different products that will help your students improve their reading skills!<br>
            Our workbooks, teacher manuals, manipulatives, and eBooks are an extension of the software that will help
            students<br>
            learn and reinforce all the skills learned in the different programs in MaxScholar.
            <br><br>
            Contact us for SCHOOL PRICING and hard copies.
        </p>
        <img src="assets/img/workbooks-main.png" alt="orton gillingham workbooks" />
    </div>
</section>
<section id="workbooks-ebooks-selector">
    <div id="selectors">
        <div (click)="selectCategory('workbooks')" [class.active]="selectedCategory == 'workbooks'">Workbooks (PDF)
        </div>
        <div (click)="selectCategory('ebooks')" [class.active]="selectedCategory == 'ebooks'">Ebooks</div>
    </div>
    <div class="workbook-info-text">
      <p>If your school/district already uses MaxScholar, please contact us for special rates at info@maxscholar.com</p>
    </div>
</section>

<div class="bgr-shapes">
    <div id="books">
        <div class="content" *ngIf="selectedCategory == 'workbooks'">
            <app-book *ngFor="let workbook of workbooks" [book]="workbook"></app-book>
        </div>
        <div class="content" *ngIf="selectedCategory == 'ebooks'">
            <app-book *ngFor="let workbook of ebooks" [book]="workbook"></app-book>
        </div>
    </div>

    <quote></quote>
</div>
