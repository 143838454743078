<section class="main-section first-section" id="support">
    <div class="content">
        <h1>FAQs</h1>
    </div>
</section>
<section id="faq">
    <div class="content">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let question of questions" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                    {{question.question}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>{{question.answer}}</p>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
</section>