const API_DOMAIN = 'https://backend.test.maxscholar.com';

export const environment = {
  production: false,
  environment: 'dev',
  api_domain: API_DOMAIN,
  mastercode_api_domain: API_DOMAIN,
  service_no_auth_api_domain: API_DOMAIN,
  service_authentication_api_domain: API_DOMAIN,
  service_core_school: API_DOMAIN,
  app_frontend_url: 'https://campus.maxscholar.com',
  dashboard_frontend_url: 'https://dashboard.test.maxscholar.com',
  cookie_domain: '.maxscholar.com',
  admin_url: 'https://test.maxscholar.com/admin/',
};
