import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  sending: boolean = false;
  contactSent: boolean = false;
  form: FormGroup;

  constructor(
    public fb: FormBuilder,
    private globals: GlobalsService,
    private dataService: DataService
  ) {
    this.form = this.fb.group({
      name: [''],
      phone: [null, [Validators.required, Validators.pattern("[\+0-9 ]{6,20}")]],
      email: [''],
      comments: ['']
    })
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "contact";
    }, 5);
  }

  onSubmit() {
    this.sending = true;
    var formData: any = new FormData();
    formData.append("name", this.form.get('name')!.value);
    formData.append("email", this.form.get('email')!.value);
    formData.append("phone", this.form.get('phone')!.value);
    formData.append("message", this.form.get('comments')!.value);

    console.log("FORM DATA", formData.get("name"))

    this.dataService.sendContact(formData).subscribe((sent)=> {
      this.contactSent = true;
      this.sending = false;
    }, (error) => {
      this.sending = false;
      alert("An error has ocurred. Please try again later.")
    });
  }

}
