import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  reviews: Array<any> = [
    {
      text: 'The Orton Gillingham training was very helpful and informative. As a preschool/kindergarten special education teacher I am walking away from this training with some great ideas to try with my students. I am excited to use this program in my small groups, as this is such an individualized program and I will be able to really push my students further. <br><br> MaxPhonics is going to be a huge hit with my students, and I am eager to see how they respond to the different aspects of the program. Some of the program, obviously, will be above my grade level, but I have learned a lot of great tools and different techniques to strengthen my students&#39; letter/sound identification. I am excited to see this program in action across the different grade levels.',
      name: 'Jess B.',
      office: 'Preschool Coordinator/Special Education Teacher'
    },
    {
      text: 'I started my MaxScholar training in the middle of the COVID- 19 pandemic. Dr. Levy was understanding and very flexible during the transition to online learning and my schedule as a special education teacher. Dr. Levy held monthly check ins and was always available for feedback and coaching as needed. The online program became a morning routine for my students and aided in reinforcing new skills. The in-person learning is hands on, auditory and visual which benefits all students. I would walk into my inclusive classrooms and hear students saying the motions to write a specific letter or orally interacting with the MaxScholar program. All 4 students I have worked through the program with have made progress towards their reading and overall confidence! Improving the reading confidence of students is a huge win with this training process, I can proudly say these 4 students volunteer to read within whole and small groups now! Thank you so much to the MaxScholar Community for the endless support! <br><br> Thank you so much!',
      name: 'Maria R.',
      office: 'SPED Teacher Grades 3 - 5'
    },
    {
      text: 'The MaxScholar training provided me with a wealth of valuable information and resources that I can use to help my students who struggle in different aspects of reading. During the 2021-22 school year I took on a new role as a Title I-Reading Interventionist. We have a few great interventions in our toolbox, but I was looking for something more comprehensive that included an explicit phonological awareness component coupled with leveled text and comprehension practice. I was excited to learn that MaxScholar has that and much more.',
      name: 'Mrs. Diana K.',
      office: 'Title I-Reading Teacher'
    },
    {
      text: 'I am very excited about what I learned during the Maxscholar training. I can hardly wait to put it into practice as I know it will greatly improve my teaching and student learning. As a fifth-grade Special Education teacher, I provide push-in and pull-out instructional support services to students in the general education setting. This year, five of my students who had reading goals read one to two years below grade level. One of my students read at a second-grade level. Throughout the training, I thought about him and his struggle and my struggle to help him. One very profound moment was watching the video of the kindergarten student, who did not know any sounds, reading sentences after three weeks of intervention. I thought to myself, I am truly blessed to be a part of this.',
      name: 'Stefanie G.',
      office: 'fifth-grade Special Education teacher'
    },
    {
      text: 'This workshop has made me feel more confident and prepared to teach my upcoming second graders literacy. I am excited to apply the Orton-Gillingham approach in my classroom this year!',
      name: 'Amanda L.',
      office: 'Second Grade Teacher'
    },
    {
      text: 'At the MaxScholar OG training yesterday, I learned a multitude of multisensory approaches to phonics that I will be gradually introducing to my classroom. I am a 4-5 grade special education teacher and I have many students who are 3-4 grade levels below in reading that will greatly benefit from the online MaxScholar program itself as well as the OG tactile work. One of the biggest aspects of the training yesterday that stuck with me was the approach to introducing/teaching how to decode multi-syllabic words. I appreciated the steps of labeling the first two vowels, connecting the vowels with a &quot;bridge&quot;, labeling the consonants in the &quot;bridge&quot;, dividing using the pattern, then decoding. I feel as though this strategy will greatly benefit my students to understand how to independently decode unfamiliar multi- syllabic words, especially by understanding the 6 patterns. Overall, I feel as though the training I received was very conducive to my students needs as well as helping me become a better instructor!',
      name: 'Alexandra S.',
      office: 'Special Education Teacher'
    },
    {
      text: 'I am so excited to have been a part of the training on Wednesday, April 27. This training is going to help me in so many ways and it is making me feel more confident about teaching reading to my students and to students that I tutor. The Max Scholar program seems like something our entire school would benefit from accessing. The way that it is structured can help all of my readers, not just the struggling ones. I look forward to continuing my education in my monthly meetings as well as practicing what I have learned. <br><br> I have attached the Homework assignment for the training session below. <br><br> Thank you so much for this incredible opportunity!',
      name: 'Holly M.',
      office: 'Grade Three Teacher'
    },
    {
      text: 'This program and the following training will be very beneficial not only for my students with dyslexia but for my at risk students as well. The program offers a diagnosis as well as a prescription to help readers succeed. I am looking forward to future training.',
      name: 'Amy G.',
      office: 'Reading Tutor'
    }
  ]

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel | undefined;

  constructor() { }

  ngOnInit(): void {
    
  }

}
