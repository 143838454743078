import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private globals: GlobalsService
    ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "privacy-policy";
    }, 5);
  }

}
