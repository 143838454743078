<form id="_payment-form" #paymentForm="ngForm" [formGroup]="form">
    <div class="container" id="payment-form">
        <div class="row">
            <div class="col-md">
                <div class="container">
                    <div class="step">
                        <h2><strong>Step #1</strong> Contact Information</h2>

                        <p>
                            <mat-form-field>
                                <mat-label>First Name</mat-label>
                                <input matInput placeholder="name" name="name" formControlName="name" id="name" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)" class="alert alert-danger">
                            <div *ngIf="form.controls['name'].errors.required">
                            Name is required.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Last Name</mat-label>
                                <input matInput placeholder="last name" name="last_name" formControlName="last_name" id="last_name" required>
                            </mat-form-field>
                        </p>
                        <div class="error" *ngIf="form.controls['last_name'].invalid && (form.controls['last_name'].dirty || form.controls['last_name'].touched)" class="alert alert-danger">
                            <div *ngIf="form.controls['last_name'].errors.required">
                            Last name is required.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Phone Number</mat-label>
                                <input matInput placeholder="phone" name="phone" formControlName="phone"  id="phone" required>
                            </mat-form-field>
                        </p>
                        <div *ngIf="form.controls['phone'].invalid && (form.controls['phone'].dirty || form.controls['phone'].touched)" class="alert alert-danger">
                            <div *ngIf="form.controls['phone'].errors.required">
                                Phone is required.
                            </div>
                            <div *ngIf="form.controls['phone'].errors.pattern">
                                Please enter a valid phone number.
                            </div>
                        </div>
                        <p>
                            <mat-form-field>
                                <mat-label>Email Address</mat-label>
                                <input matInput placeholder="email" name="email" formControlName="email"  id="email" email required>
                            </mat-form-field>
                        </p>
                        <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="alert alert-danger">
                            <div *ngIf="form.controls['email'].errors.required">
                                Email is required.
                            </div>
                            <div *ngIf="form.controls['email'].errors.email">
                                Please enter a valid email address.
                            </div>
                        </div>

                        <div *ngIf="hasTrainingAdded">
                            <p>
                                <mat-form-field>
                                    <mat-label>I teach</mat-label>
                                    <input matInput placeholder="What you teach" name="what_teach" formControlName="what_teach" id="what_teach" required>
                                </mat-form-field>
                            </p>
                            <div class="error" *ngIf="form.controls['what_teach'].invalid && (form.controls['what_teach'].dirty || form.controls['what_teach'].touched)" class="alert alert-danger">
                                <div *ngIf="form.controls['what_teach'].errors.required">
                                    This field is required.
                                </div>
                            </div>

                            <p>
                                <mat-form-field>
                                    <mat-label>At</mat-label>
                                    <input matInput placeholder="Where you teach" name="where_teach" formControlName="where_teach" id="where_teach" required>
                                </mat-form-field>
                            </p>
                            <div class="error" *ngIf="form.controls['where_teach'].invalid && (form.controls['where_teach'].dirty || form.controls['where_teach'].touched)" class="alert alert-danger">
                                <div *ngIf="form.controls['where_teach'].errors.required">
                                    This field is required.
                                </div>
                            </div>


                            <div [formGroup]="form.controls.iam_teach">
                                <p style="text-align:left;">
                                    <mat-label>I am a (check all that apply)</mat-label>
                                </p>
                                <mat-checkbox *ngFor="let availableDay of teachCategories;" formControlName="{{availableDay}}">{{getTeachCategoryDisplay(availableDay)}}</mat-checkbox>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md">
                <div class="step">
                    <h2><strong>Step #2:</strong>  Billing / Shipping Address (USA only)</h2>

                    <p>
                        <mat-form-field>
                            <mat-label>Street Address</mat-label>
                            <input matInput placeholder="Street Address" name="street_address" formControlName="street_address" id="street_address">
                        </mat-form-field>
                    </p>
                    <div class="error" *ngIf="form.controls['street_address'].invalid && (form.controls['street_address'].dirty || form.controls['street_address'].touched)" class="alert alert-danger">
                        <div *ngIf="form.controls['street_address'].errors.required">
                        Street Address is required.
                        </div>
                    </div>
                    <p>
                        <mat-form-field>
                            <mat-label>City</mat-label>
                            <input matInput placeholder="City" name="city" formControlName="city" id="city">
                        </mat-form-field>
                    </p>
                    <div class="error" *ngIf="form.controls['city'].invalid && (form.controls['city'].dirty || form.controls['city'].touched)" class="alert alert-danger">
                        <div *ngIf="form.controls['city'].errors.required">
                        City is required.
                        </div>
                    </div>
                    <p>
                        <mat-form-field>
                            <mat-label>State</mat-label>
                            <select matNativeControl name="state" formControlName="state"  id="state">
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                          </mat-form-field>
                    </p>
                    <div *ngIf="form.controls['state'].invalid && (form.controls['state'].dirty || form.controls['state'].touched)" class="alert alert-danger">
                        <div *ngIf="form.controls['state'].errors.required">
                        State is required.
                        </div>
                    </div>
                    <p>
                        <mat-form-field>
                            <mat-label>Zip Code</mat-label>
                            <input matInput placeholder="Zip Code" name="zip_code" formControlName="zip_code"  id="zip_code">
                        </mat-form-field>
                    </p>
                    <div *ngIf="form.controls['zip_code'].invalid && (form.controls['zip_code'].dirty || form.controls['zip_code'].touched)" class="alert alert-danger">
                        <div *ngIf="form.controls['zip_code'].errors.required">
                        Zip Code is required.
                        </div>
                        <div *ngIf="form.controls['zip_code'].errors.pattern">
                            Please enter a valid Zip Code.
                        </div>
                    </div>
                </div>
                <div class="step">
                    <h2><strong>Step #3:</strong> Secure Checkout</h2>

                    <p class="total_price"><strong>TOTAL:</strong> <strong>${{total}}</strong></p>

                    <div class="cell example example4" id="example-4">
                        <div id="example4-paymentRequest">
                        <!--Stripe paymentRequestButton Element inserted here-->
                        </div>
                        <fieldset>
                        <legend class="card-only" data-tid="elements_examples.form.pay_with_card">Pay with card</legend>
                        <legend class="payment-request-available" data-tid="elements_examples.form.enter_card_manually">Or enter card details</legend>
                        <div class="container">
                            <div id="example4-card"></div>
                            <button (click)="createToken()" [disabled]="sendingPayment" class="orange-button checkout-button">CHECKOUT</button>
                        </div>
                        </fieldset>
                        <div id="card-errors" class="error" role="alert">
                            <span class="message"></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
