import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CheckUserComponent } from '../check-user/check-user.component';
import { Product } from '../classes/product';
import { Training } from '../classes/training';
import { CartService } from '../services/cart.service';

@Component({
  selector: 'training-item',
  templateUrl: './training-item.component.html',
  styleUrls: ['./training-item.component.scss']
})
export class TrainingItemComponent implements OnInit {

  @Input() training: Training;
  @Input() trainingClass: string;
  
  unique_id: string;

  showDetail: boolean = false;

  registered: boolean = false;

  constructor(
    private cartService: CartService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.unique_id = "training_"+this.training.id;
  }


  openDetails() {
    this.showDetail = !this.showDetail;
  }

  chooseTraining() {
    if (this.training.kind == "OG") {
      let product = new Product();
      product.title = this.training.title + "\n (" +this.training.date+")";
      product.category = 'training';
      product.description = this.training.location;
      product.price = this.training.price;
      product.id = this.training.id;
      product.unique_id = this.unique_id;
      product.cover = "/assets/img/training-icon.png";
      product.quantity = 1;

      this.cartService.addToCart(product);
      this.router.navigate(['checkout']);
    } else if (this.training.kind == "OW" || this.training.kind == "IST") {
      this.confirmUser();
    }
  }


  confirmUser() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        kind: this.training.kind
    };

    const dialogRef = this.dialog.open(CheckUserComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == "success") {
          // SHOW ZOOM CODE HERE, SET AS REGISTERED
          this.registered = true;
        } else if (data == "contact") {
          this.router.navigate(['/contact']);
        }
      }
    );    
  }

  insertBreakAtDash(text: string): string {
    let modified_text = text.substring(0,text.indexOf("-")-1)+"<br/>"+text.substring(text.indexOf("-")+1,text.length);
    return modified_text;
  }
}
