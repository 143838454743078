<section class="main-section first-section left-aligned-header" id="tutoring">
    <div class="content">
        <h1>One-on-One Tutoring</h1>
        <p>We are now able to provide remote tutoring to individual students at home or at school.</p>
        <button routerLink="/contact" class="orange-button">CONTACT US</button> <span style="padding-left:15px;">FREE
            assessment!</span>
    </div>
</section>

<div class="bgr-shapes">


    <section id="tutoring-content">
        <div class="container">
            <h3 class="subtitle">MaxScholar Tutoring</h3>
            <h1>Improve up to Two Grade Levels
                with One-on-One Tutoring</h1>
            <p>Virtual / In-Person</p>

            <img src="/assets/img/tutoring-img-1.jpg" alt="orton gillingham training" />

            <h3 style="margin-top:2em;">One-on-One Tutoring</h3>
            <h3 class="subtitle">Students in Grades K-12</h3>

            <div class="content tutoring-list">
                <div class="row">
                    <div class="col-md">
                        <span class="bull">Does your child struggle with Dyslexia, ADHD, Dysgraphia, or Visual/Auditory
                            processing disorders?</span>
                    </div>
                    <div class="col-md">
                        Learn how your child can improve in reading, writing, and executive functioning skills using our
                        multi-sensory techniques. Using information you provide, and our online assessment, we can
                        create a learning plan geared toward your child's needs.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <span class="bull">Do you want to improve your child’s skills up to TWO grade levels?</span>
                    </div>
                    <div class="col-md">
                        We can assist your child in making improvements using our individualized methods and our OG
                        software. We can provide the drill and practice that most children need to improve.
                    </div>
                </div>
            </div>

            <div class="call-us">
                <a href="tel:800-845-5640" class='orange-button'><img src="/assets/img/phone.svg" alt="phone" />
                    800-845-5640</a>
                <span>Call us now for a
                    FREE assessment!</span>
            </div>

        </div>
    </section>
</div>