import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Student } from '../classes/students';
import { APP_FRONTEND_URL, COOKIE_DOMAIN } from '../constants/constants';
import { ClassLoginService } from '../services/class-login.service';

@Component({
  selector: 'app-class-login',
  templateUrl: './class-login.component.html',
  styleUrls: ['./class-login.component.scss']
})
export class ClassLoginComponent implements OnInit {

  private clase_id: string;

  students: Array<Student>;
  class_name: string;
  loadingScreen = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private classLoginService: ClassLoginService,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) {

  }

  ngOnInit(): void {
    this.clase_id = this.route.snapshot.paramMap.get('clase_uuid');
    this.classLoginService.getStudentsClassLogin(this.clase_id).subscribe(
      (response) => {
        this.students = response.students;
        this.class_name = response.class_name;
        this.loadingScreen = false;
      },
      (error: HttpErrorResponse) => {
        this.loadingScreen = false;
        if (error.status == 404) {
          alert(`Class ${this.clase_id} not exists. Please, try with another one.`);
        } else if (error.status == 403) {
          alert("You don not have permissions to see this Class")
        } else {
          alert("An unexpected error happened. Please contact the system administrator.");
          console.log(error);
        }
        this.router.navigate(['/']);
      }
    );
  }

  loginStudent(student: Student) {
    this.loadingScreen = true;
    const username = student['username'];

    const dialogRef = this.dialog.open(ConfirmClassLoginDialog, {
      width: '400px',
      data: username
    });

    dialogRef.afterClosed().subscribe((doLogin: any) => {
      if (doLogin) {
        this.classLoginService.doClassLogin(username).subscribe(
          (response) => {
            console.log(response);
            const student_jwt_token = response['token'];
            this.cookieService.set('JWT', student_jwt_token, { path: '/', domain: COOKIE_DOMAIN });
            this.cookieService.set('PREJWT', student_jwt_token, { path: '/', domain: COOKIE_DOMAIN });
            window.location.href = APP_FRONTEND_URL;
            this.loadingScreen = false;
          },
          (error: HttpErrorResponse) => {
            this.loadingScreen = false;
            alert(error.statusText);
          });
      } else {
        this.loadingScreen = false;
        dialogRef.close(false);
      }
    });
  }

}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.html',
})
export class ConfirmClassLoginDialog {


  constructor(
    public dialogRef: MatDialogRef<ConfirmClassLoginDialog>,
    @Inject(MAT_DIALOG_DATA) public username: string
  ) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
