import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../services/globals.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    public globals: GlobalsService,
    private meta: Meta,
    private title: Title
  ) {}

  paused = false;
  //unpauseOnArrow = false;
  //pauseOnIndicator = false;
  //pauseOnHover = true;
  //pauseOnFocus = true;

  @ViewChild('videoPlayer') videoplayer: ElementRef | undefined;
  videoPlaying: boolean = false;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel | undefined;

  /*
  togglePaused() {
    if (this.paused) {
      this.carousel!.cycle();
    } else {
      this.carousel!.pause();
    }
    this.paused = !this.paused;
  }
  */

  onSlide(slideEvent: NgbSlideEvent) {
    /*
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
    */

    const slideIndex = parseInt(slideEvent.current.replace("slide_", ""), 10);

    this.globals.lightBgr = slideIndex == 3 || slideIndex == 4;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "";
    }, 5);

    this.title.setTitle("Orton-Gillingham Online Programs for Reading | MaxScholar");
    this.meta.addTags([
      { name: "title", content: "Orton-Gillingham Online Programs for Reading | MaxScholar" },
      { name: "keywords", content: "Orton-Gillingham Programs, Programs for Reading Comprehension, Online Reading Intervention Programs, Best Reading Intervention Programs, Orton-Gillingham Online Programs" },
      { name: "description", content: "MaxScholar's Orton-Gillingham online programs for Reading use research-based methods to build reading, writing & comprehension skills. Get a Free trial today." }
    ])
  }

  playPauseVideo() {
    if (!this.videoPlaying) {
      this.videoPlaying = true;
      this.videoplayer!.nativeElement.play();
      this.videoplayer!.nativeElement.controls = true;
    }
  }
}
