import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Book } from '../classes/book';
import { DataService } from '../services/data.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-workbooks-ebooks',
  templateUrl: './workbooks-ebooks.component.html',
  styleUrls: ['./workbooks-ebooks.component.scss']
})
export class WorkbooksEbooksComponent implements OnInit {

  workbooks: Array<Book> = [];
  ebooks: Array<Book> = [];

  selectedCategory: string = 'workbooks';

  constructor(
    private globals: GlobalsService,
    private dataService: DataService,
    private meta: Meta,
    private title: Title
  ) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "workbooks";
    }, 5);
    this.title.setTitle("Best Orton-Gillingham Workbooks and eBook | MaxScholar");
    this.meta.addTags([
      { name: "title", content: "Best Orton-Gillingham Workbooks and eBook | MaxScholar" },
      { name: "keywords", content: "Best Reading Comprehension Workbooks, Orton-Gillingham Workbooks" },
      { name: "description", content: "Our Orton-Gillingham workbooks will help students to reinforce all the skills learned in the different MaxScholar programs. Contact us for school pricing today." }
    ])
    this.dataService.getBooks().subscribe((response: Object) => {
      let _response = Object.values(response);

      _response.forEach((_book) => {
        if (_book.category == 'WORKBOOK') {
          this.workbooks.push(_book);
        } else {
          this.ebooks.push(_book);
        }
      });
    });
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
  }

}
