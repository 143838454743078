import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { License } from '../classes/license';
import { APP_FRONTEND_URL, COOKIE_DOMAIN, DASHBOARD_FRONTEND_URL } from '../constants/constants';
import { DataService } from '../services/data.service';
import { GlobalsService } from '../services/globals.service';
import { JWTService } from '../services/jwt.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  sending: boolean = false;
  sendingTrial:boolean = false;
  loginForm: FormGroup;
  trialForm: FormGroup;

  createFreeTrial: boolean = false;

  stripe: any = (window as any).stripe;
  card: any;
  elements: any;
  sendingPayment: boolean = false;

  licenses: Array<License>;
  selectedLicense: any = 32;

  gradesList: string[] = ['k', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  disable_license_by_grade = [true, false, true];

  constructor(
    public fb: FormBuilder,
    private globals: GlobalsService,
    private dataService: DataService,
    private cookieService: CookieService,
    private jwtService: JWTService,
    private route: ActivatedRoute
  ) {

    this.loginForm = this.fb.group({
      username: [null],
      password: [null]
    });

    this.trialForm = this.fb.group({
      student_first_name: [null],
      student_last_name: [null],
      email: [null],
      username_trial: [null],
      password_trial: [null],
      grade: [this.gradesList[0]],
      reading_level: [null],
      name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      street_address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip_code: ['', [Validators.required, Validators.pattern("[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]")]],
      stripe_token: [null]
    });

    this.onChanges();
  }

  onChanges(): void {
    this.trialForm.valueChanges.subscribe(val => {
      const grade = val['grade'];
      const grade_number = Number(grade);
      if (grade !== null) {
        if (grade === 'k') {
          // Early Start and Maxguru
          this.disable_license_by_grade = [true, false, true];
          this.selectLicense(30);
        } else if (grade_number <= 2) {
          // K-2 and MaxGuru
          this.disable_license_by_grade = [true, true, false];
          this.selectLicense(30);
        } else {
          // Only Maxguru
          this.selectLicense(30);
          this.disable_license_by_grade = [true, false, false];
        }
      } else {
        this.disable_license_by_grade = [true, true, true];
      }

    });
  }

  ngOnInit(): void {

    this.licenses = this.globals.licenses;

    this.selectedLicense = this.route.snapshot.queryParamMap.get('licenseType')||false;
    if (!this.selectedLicense) this.selectedLicense = this.licenses[0].id;

    const jwt = this.cookieService.get('JWT');
    if (jwt !== '') {
      const payload = this.jwtService.DecodeToken(jwt);

      if (payload['category'] === 'student' || payload['is_demouser']) {
        window.location.href = APP_FRONTEND_URL;
      } else {
        window.location.href = DASHBOARD_FRONTEND_URL;
      }
    }

    this.createFreeTrial = false;

    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "login";
    }, 5);

        //STRIPE
        this.elements = this.stripe.elements({
          locale: 'auto'
      });

      /**
         * Card Element
         */
      this.card = this.elements.create("card", {
        style: {
          base: {
            color: "#32325D",
            fontWeight: 500,
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#CFD7DF"
            }
          },
          invalid: {
            color: "#E25950"
          }
        }
      });

      this.card.mount("#example4-card");
  }


  loginSubmit() {
    this.enableSending(false);

    var loginFormData: any = new FormData();
    loginFormData.append("username", this.loginForm.get('username').value);
    loginFormData.append("password", this.loginForm.get('password').value);

    this.dataService.doLogin(loginFormData).subscribe((response) => {
      const token = response['token'];
      this.cookieService.set('JWT', token, {domain: COOKIE_DOMAIN});
      this.cookieService.set('PREJWT', token, { domain: COOKIE_DOMAIN });
      const category = response['category'];
      const is_demouser = response['is_demouser'];
      if (category == 'student' || is_demouser) {
        window.location.href = APP_FRONTEND_URL;
      } else {
        window.location.href = DASHBOARD_FRONTEND_URL;
      }

    }, (error) => {
      if ('detail' in error.error) {
        const detail = error.error['detail'];
        alert(detail);
      } else {
        alert("An error has occurred. Please try again later.");
      }

      this.enableSending(true);
    })

  }

  enableSending(enable: boolean) {
    this.sending = !enable;
    this.sendingTrial = !enable;
  }

  trialSubmit() {
    if (this.trialForm.valid) {

      this.enableSending(false);
      // OBJECT TO SEND
      let obj = {
        //STUDENT DATA
        'student_data': {
          'first_name': this.trialForm.controls['student_first_name'].value,
          'last_name': this.trialForm.controls['student_last_name'].value,
          'grade': this.trialForm.controls['grade'].value,
          'reading_level': this.trialForm.controls['reading_level'].value,
          'username': this.trialForm.controls['username_trial'].value,
          'password': this.trialForm.controls['password_trial'].value,
          'license_id': this.globals.getLicense(this.selectedLicense).id,
        },
        // STRIPE DATa
        'stripe_data': {
          'stripe_token': this.trialForm.controls['stripe_token'].value,
          'amount': this.globals.getLicense(this.selectedLicense).price,
          'billing_details': {
            'address': {
              'city': this.trialForm.controls['city'].value,
              'country': 'US',
              'line1': this.trialForm.controls['street_address'].value,
              'state': this.trialForm.controls['state'].value,
              'postal_code': this.trialForm.controls['zip_code'].value
            },
            "email": this.trialForm.controls['email'].value,
            "name": this.trialForm.controls['last_name'].value+", "+this.trialForm.controls['name'].value,
            "phone": "-"
          }
        }
      }

      this.dataService.createTrialSubscription(obj).subscribe((response) => {
        const token = response['jwt'];
        this.cookieService.set('JWT', token, {domain: COOKIE_DOMAIN});
        this.cookieService.set('PREJWT', token, { domain: COOKIE_DOMAIN });
        window.location.href = APP_FRONTEND_URL;
      }, (error) => {
        console.log(error);
        if ('detail' in error.error) {
          const detail = error.error['detail'];
          alert(detail);
        } else if ('detail' in error.error.error) {
          const detail = error.error.error['detail'];
          alert(detail);
        } else{
          alert("An error has occurred. Please try again later.");
        }
        this.enableSending(true);
      })
    } else {
      this.validateAllFormFields(this.trialForm);
    }
  }


  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(this.trialForm.controls).forEach(field => { // {1}
      const control = this.trialForm.get(field);            // {2}
      control.markAsTouched();       // {3}
    });
  }

  // FREE TRIAL STRIPE
  createToken() {
    this.sendingPayment = true;

    this.stripe.createToken(this.card).then((result: { error: { message: string; }; token: any; }) => {
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        this.sendingPayment = false;
      } else {
        // Send the token to your server
        this.stripeTokenHandler(result.token);
      }
    });
  };
  stripeTokenHandler(token: { id: string; }) {
    // Insert the token ID into the form so it gets submitted to the server
    this.trialForm.controls['stripe_token'].setValue(token.id);

    // Submit the form
    this.trialSubmit();
  }

  selectLicense(id: number) {
    this.selectedLicense = id;
  }

}
