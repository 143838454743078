<section class="main-section first-section" id="software">
    <div class="content">
        <h1>What is MAXSCHOLAR?</h1>
        <p>MaxScholar is an Orton-Gillingham based, Structured Literacy program which is explicit, systematic, and
            multi-sensory.<br>It addresses Phonemic Awareness, Phonics, Fluency, Vocabulary, and Reading Comprehension.
        </p>
        <img src="assets/img/maxscholar-home.png" alt="Online reading intervention programs" />
        <img class="free-trial-img" src="assets/img/15-day-free-trial.png" alt="15-day free trial" />
    </div>
</section>
<div class="bgr-shapes">
    <section id="at-home-at-school">
        <div class="content">

            <div class="at-home" routerLink="['/login', {'licenseType': 0}]">
                <img src="assets/img/at-home.png" alt="orton gillingham homeschool" />
                <h2>At Home</h2>
                <p>Our programs can be used on any device, allowing students to work on their reading skills in school,
                    at home, or even on the go!<br><br></p>
                <button class="orange-button">15-DAY FREE TRIAL</button>
            </div>
            <div class="at-school" routerLink="/school-licenses">
                <img src="assets/img/at-school.png" alt="orton gillingham reading programs" />
                <h2>At School</h2>
                <p>MaxScholar is the ideal blended learning solution for schools. We have supporting resources and
                    strong analytics to assist the teacher in providing specific data-driven instruction for each
                    student.</p>
                <button class="orange-button">SCHOOL LICENSE</button>
            </div>

        </div>
    </section>
    <section id="software-licenses">
        <div class="container">
            <div class="row">
                <div class="col-7">
                    <h3 class="subtitle">MAXSCHOLAR Store</h3>
                    <h2>SOFTWARE LICENSES</h2>
                    <p>MaxScholar software is licensed on an annual subscription basis and can be purchased for
                        individual home use or by schools, school districts, or tutoring centers. We offer affordable
                        options for schools and districts, including discounted multi-year discounts.</p>
                    <button class="orange-button" routerLink="/home-licenses">HOME LICENSE</button>
                    <button class="orange-inverse-button" routerLink="/school-licenses">SCHOOL LICENSE</button>

                </div>
                <div class="col-5">
                    <img src="assets/img/students-maxscholar.jpg" alt="og reading program" />
                </div>
            </div>
        </div>
    </section>
    <quote></quote>
</div>