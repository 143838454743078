import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MASTERCODE_API_DOMAIN, SERVICE_AUTHENTICATION_API_DOMAIN, SERVICE_NO_AUTH_API_DOMAIN } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(
    private httpClient: HttpClient
  ) { }

  createAuthorizationHeader() {
    let headers = new HttpHeaders();
    //headers.append('Access-Control-Allow-Origin', '*');
    return headers;
  }

  public getBooks(){
    let headers = this.createAuthorizationHeader();
    return this.httpClient.get(SERVICE_NO_AUTH_API_DOMAIN+"/no_auth/workbooks/", {
      headers: headers
    });
  }

  public getTrainingCourses(){
    let headers = this.createAuthorizationHeader();
    return this.httpClient.get(SERVICE_NO_AUTH_API_DOMAIN+"/no_auth/trainings/", {
      headers: headers
    });
  }

  public checkTrainingUser(formData: Object ): Observable<any> {
    return this.httpClient.post(SERVICE_NO_AUTH_API_DOMAIN+"/no_auth/nopayment_training/", formData);
  }


  public getFaq() {
    let headers = this.createAuthorizationHeader();
    return this.httpClient.get(SERVICE_NO_AUTH_API_DOMAIN+"/no_auth/faqs/", {
      headers: headers
    });
  }

  public sendContact(formData: FormData ): Observable<any> {
    return this.httpClient.post(SERVICE_NO_AUTH_API_DOMAIN+"/no_auth/contact/", formData);
  }

  public sendPayment(data: Object): Observable<any> {
    return this.httpClient.post(SERVICE_NO_AUTH_API_DOMAIN+"/no_auth/payments/stripe/", data);
  }

  public createTrial(data: Object): Observable<any> {
    return this.httpClient.post(MASTERCODE_API_DOMAIN+"/mymax/trial/", data);
  }

  public createTrialSubscription(data: Object): Observable<any> {
    return this.httpClient.post(SERVICE_NO_AUTH_API_DOMAIN+"/no_auth/payments/stripe/subscription/", data);
  }

  public doLogin(data: FormData): Observable<any> {
    return this.httpClient.post(SERVICE_AUTHENTICATION_API_DOMAIN+"/auth/login/", data);
  }

  public resetPassword(data: FormData): Observable<any> {
    return this.httpClient.post(SERVICE_AUTHENTICATION_API_DOMAIN+"/auth/recover_password/", data);

  }

  public setPassword(data: FormData): Observable<any> {
    return this.httpClient.post(SERVICE_AUTHENTICATION_API_DOMAIN+"/auth/set_recovered_password/", data);
  }


}
