import { Component, Inject, Input, OnInit } from '@angular/core';
import { Product } from '../classes/product';
import { CartService } from '../services/cart.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'checkout-item',
  templateUrl: './checkout-item.component.html',
  styleUrls: ['./checkout-item.component.scss']
})
export class CheckoutItemComponent implements OnInit {

  @Input() item: Product;

  productQuantity: number;
  
  constructor(
    private cartService: CartService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.productQuantity = this.item.quantity;
  }

  plus() {
    this.productQuantity += 1;
    this.cartService.updateItemQuantity(this.item.unique_id, this.productQuantity);
  }
  
  confirmDelete() {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        width: '400px',
      });
  
      dialogRef.afterClosed().subscribe((doDelete: any) => {
        // REMOVE ITEM
        if (doDelete) {
          this.cartService.removeFromCart(this.item.unique_id);
        }
      });
  }

  minus() {
    if (this.productQuantity-1 == 0) {
      this.confirmDelete();
    } else {
      this.productQuantity -= 1;
      this.cartService.updateItemQuantity(this.item.unique_id, this.productQuantity);
    }
  }
  
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.html',
})
export class ConfirmDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}