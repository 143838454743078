import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-learning-disabilities',
  templateUrl: './learning-disabilities.component.html',
  styleUrls: ['./learning-disabilities.component.scss']
})
export class LearningDisabilitiesComponent implements OnInit {

  constructor(
    private globals: GlobalsService,
    private meta: Meta,
    private title: Title
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "support";
    }, 5);
    this.title.setTitle("Best Online Reading Programs for Dyslexia | MaxScholar");
    this.meta.addTags([
      { name: "title", content: "Best Online Reading Programs for Dyslexia | MaxScholar" },
      { name: "keywords", content: "Dyslexia Reading Programs, Best Reading Programs for Dyslexia, Best Online Programs for Dyslexia, Best Online Reading Programs for Dyslexia" },
      { name: "description", content: "MaxScholar is the Best Online Reading Programs for Dyslexia to help students to get past their learning difficulties. Contact us for a free assessment today." }
    ])
  }
}
