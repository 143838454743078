import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  panelOpenState = false;


  questions: Array<Object> = []

  constructor(
    private globals: GlobalsService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = true;
      this.globals.activePage = "support";
    }, 5);

    this.dataService.getFaq().subscribe((response: Object)=>{
      this.questions = Object.values(response);
    });
  }

}
