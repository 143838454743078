<section class="main-section first-section" id="training">
    <div class="content">
        <h1>OG Training & Certification</h1>
        <p>Our Professional Development courses are delivered in-person or via webinar.
            Our workbooks and teacher manuals also provide specific directions for each teacher to follow in the use of each program in different teaching environments.</p>
        <img src="assets/img/og-certification.png" alt="orton gillingham certification" />
    </div>
</section>
<div class="bgr-shapes">

    
    <section id="og-training">
        <div class="container">
            
            <h3 class="subtitle">MAXSCHOLAR Store</h3>
            <h2>Upcoming OG Training & Certification Courses</h2>

            <div class="individual-registration">
                <h4>OG Training</h4>
            
                <div class="training-header">
                    <div class="row">
                        <div class="col-3">
                            <h6>Title</h6>
                        </div>
                        <div class="col-2">
                            <h6>Date</h6>
                        </div>
                        <div class="col-2">
                            <h6>Location</h6>
                        </div>
                        <div class="col-2">
                            <h6>Price</h6>
                        </div>
                        <div class="col-3">
                            <h6>Availability</h6>
                        </div>
                    </div>
                </div>

                <training-item *ngFor="let training of og_trainings;let i = index;" [trainingClass]="'training0'" [training]="training"></training-item>


                <h4>Open Webinars</h4>

                <training-item *ngFor="let training of ow_trainings;let i = index;" [trainingClass]="'training1'" [training]="training"></training-item>

                <h4>Initial Software Training</h4>

                <training-item *ngFor="let training of ist_trainings;let i = index;" [trainingClass]="'training2'" [training]="training"></training-item>

            </div>
            
        </div>
    </section>

    <reviews></reviews>
</div>