import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../services/globals.service';

@Component({
  selector: 'app-doe-funding',
  templateUrl: './doe-funding.component.html',
  styleUrls: ['./doe-funding.component.scss']
})
export class DoeFundingComponent implements OnInit {

  constructor(
    private globals: GlobalsService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.lightBgr = false;
      this.globals.activePage = "support";
    }, 5);
  }

}
